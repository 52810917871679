import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
import './Layout1.css';

export class Layout1 extends Component {
    render() {
        return (
            <React.Fragment>

                <div className='d-flex h-100vh bg-image'>
                    <Outlet />
                </div>
                {/* <div>
                    <NavLink to="/registration"> Registration </NavLink>
                    <NavLink to="/login"> Login </NavLink>
                </div>
                <div>

                </div>
                <div>Footer</div> */}

            </React.Fragment>
        )
    }
}

export default Layout1


