import React, { Component } from 'react'
import bluefin from '../../images/Addcart/Bluefin-big.jpg'
import './Addcart.css'
import img3 from '../../images/Topselling/topsellingfish2.jpg';
import $ from 'jquery';
import Slider from 'react-slick'
import userimg from '../../images/userimg.png'

export class Addcart extends Component {
  componentDidMount() {
    var buttonPlus = $(".qty-btn-plus");
    var buttonMinus = $(".qty-btn-minus");

    var incrementPlus = buttonPlus.click(function () {
      var $n = $(this)
        .parent(".qty-container")
        .find(".input-qty");
      $n.val(Number($n.val()) + 1);
    });

    var incrementMinus = buttonMinus.click(function () {
      var $n = $(this)
        .parent(".qty-container")
        .find(".input-qty");
      var amount = Number($n.val());
      if (amount > 0) {
        $n.val(amount - 1);
      }
    });
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    return (
      <div className='addcart'>
        <div className='w-100 bg-white d-flex flex-column flex-lg-row box-shadow px-4 py-5 radius-11'>
          <div className='w-50-resp-mob me-5'>
            <img src={bluefin} alt='' className='addcartmeatsize'></img>
          </div>
          <div className='w-50-resp-mob'>
            <div className='f-md fw-600 mb-3'>Golden Promfret<span className='meatweight brick-clr f-13 ms-2 p-1'>5kg</span></div>
            <div className='d-flex align-items-center mb-3'>
              <div className='f-xs fw-600 me-2' data-toggle="modal" data-target="#exampleModalreview"><span className='fullborderblack p-1'>4<i class="fa-solid fa-star lightbrick"></i></span><span className='fullborderblack p-1 leftnone'>77 ratings</span></div>
              <div className='f-xs fw-500 status green'>Out of Stock</div>
            </div>
            <div className='mb-3'>
              <button className='f-13 px-5 mb-3 fw-500 lightbrick-btn me-2'>Add to Bag</button>
              {/* ----------------- */}
              <div class="qty-container">
                <button class="qty-btn-minus  btn-cornered mr-1" type="button"><i class="fa fa-minus"></i></button>
                <input type="text" name="qty" value="0" class="input-qty input-cornered" />
                <button class="qty-btn-plus  btn-cornered ml-1" type="button"><i class="fa fa-plus"></i></button>


              </div>
              {/* ----------------- */}
            </div>
            <div className='mb-3'>
              <div className='f-xxs clr-898989 fw-500 mb-1'>Description</div>
              <div className='f-xxs clr-898989 fw-500 w-75'>
                Ut velit inventore quo placeat ipsa et consequatur porro est volporro est voluptates debitis in harum recusandae hic molestias laudantium. Utlok iusto esseteriemen.
              </div>
            </div>
            <div>
              <div className='f-xs fw-600'>DELIVERY OPTIONS</div>
              <div className='relative w-50'>
                <input className='inp-D9D9D9 w-100 f-13 pe-6 fw-500' placeholder='Enter Postal Code'></input>
                <div className='absolute f-xs fw-600 brick-clr position-middle-r'>Check</div>
              </div>
              <div className='f-13 fw-500 mt-2'>Please enter Postal code to check delivery time & Pay on Delivery Availability</div>
            </div>
          </div>
        </div>
        <div className='w-100 my-4'>
          <div className='bg-F9F9F9 py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center mb-3'>You may also like</div>
          <div className='bestdealcarousal addcart'>
            <Slider ref={slider => (this.slider = slider)} {...settings}>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className='px-3'>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>

            </Slider>
            {/* <div style={{ textAlign: "center" }} className='p-3'>
                <button className="button" onClick={this.play}>
                  <i class="fa-regular fa-circle-play brick-clr"></i>
                </button>
                <button className="button" onClick={this.pause}>
                  <i class="fa-regular fa-circle-pause brick-clr"></i>
                </button>
              </div> */}
          </div>
        </div>
{/* ivde mandaaan ramesh */}
        <div className="modal fade" id="exampleModalreview" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className='brick-clr fw-600 f-xs mb-3'>Ratings & reviews (43)</div>
                <div className='bestdealcarousal reviewaddcart'>
                  <Slider ref={slider => (this.slider = slider)} {...settings}>


                    <div className='px-3'>
                      <div className='item p-3'>
                        <img src={userimg} alt='' className='rounduser mb-2'></img>
                        <div className='f-13 mb-2 fw-500 text-justify'>
                          These prime steaks are a cut
                          above the rest. Juicy, flavorful,
                          and incredibly tender. They truly
                          melt in your mouth. The quality is
                          evident from the first bite.
                        </div>
                        <div>
                          <button><i class="fa-regular fa-thumbs-up"></i></button>
                          <button><i class="fa-regular fa-thumbs-down"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      <div className='item p-3'>
                        <img src={userimg} alt='' className='rounduser mb-2'></img>
                        <div className='f-13 mb-2 fw-500 text-center'>
                          These prime steaks are a cut
                          above the rest. Juicy, flavorful,
                          and incredibly tender. They truly
                          melt in your mouth. The quality is
                          evident from the first bite.
                        </div>
                        <div>
                          <button><i class="fa-regular fa-thumbs-up"></i></button>
                          <button><i class="fa-regular fa-thumbs-down"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      <div className='item p-3'>
                        <img src={userimg} alt='' className='rounduser mb-2'></img>
                        <div className='f-13 mb-2 fw-500'>
                          These prime steaks are a cut
                          above the rest. Juicy, flavorful,
                          and incredibly tender. They truly
                          melt in your mouth. The quality is
                          evident from the first bite.
                        </div>
                        <div>
                          <button><i class="fa-regular fa-thumbs-up"></i></button>
                          <button><i class="fa-regular fa-thumbs-down"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      <div className='item p-3'>
                        <img src={userimg} alt='' className='rounduser mb-2'></img>
                        <div className='f-13 mb-2 fw-500'>
                          These prime steaks are a cut
                          above the rest. Juicy, flavorful,
                          and incredibly tender. They truly
                          melt in your mouth. The quality is
                          evident from the first bite.
                        </div>
                        <div>
                          <button><i class="fa-regular fa-thumbs-up"></i></button>
                          <button><i class="fa-regular fa-thumbs-down"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      <div className='item p-3'>
                        <img src={userimg} alt='' className='rounduser mb-2'></img>
                        <div className='f-13 mb-2 fw-500'>
                          These prime steaks are a cut
                          above the rest. Juicy, flavorful,
                          and incredibly tender. They truly
                          melt in your mouth. The quality is
                          evident from the first bite.
                        </div>
                        <div>
                          <button><i class="fa-regular fa-thumbs-up"></i></button>
                          <button><i class="fa-regular fa-thumbs-down"></i></button>
                        </div>
                      </div>
                    </div>




                  </Slider>
                  {/* <div style={{ textAlign: "center" }} className='p-3'>
                <button className="button" onClick={this.play}>
                  <i class="fa-regular fa-circle-play brick-clr"></i>
                </button>
                <button className="button" onClick={this.pause}>
                  <i class="fa-regular fa-circle-pause brick-clr"></i>
                </button>
              </div> */}
                </div>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Addcart
