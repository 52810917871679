import React, { Component } from 'react'
import './Addcustomer.css'
import img1 from '../../images/AddCustomer/img1.svg'


export class Addcustomer extends Component {
  render() {
    return (
      <div className='d-flex flex-column flex-md-row p-4 w-100'>
        <div className='w-40-resp-mob box-shadow me-3 radius-7 py-4 mb-3 mb-md-0'>
            <div className='text-center black-clr'>
                <img className='cus-profileimg' src={ img1 } alt=''></img>
                <div className='fw-600 mt-2'>Marianna Christy</div>
                <div className='fw-500 f-xxs'>marianna@gmail.com</div>
            </div>
            <div className='fw-600 f-xs mt-3 black-clr px-4 dotted-border-btm pb-2'>Basic Details</div>
            <div className='mt-3 pb-2'>
                <div className='f-xs fw-500 px-4'>Email</div>
                <div className='f-13 px-4 fw-500 clr-898989'>darwincharles@gmail.com</div>
            </div>
            <div className='mt-2 pb-2'>
                <div className='f-xs fw-500 px-4'>Phone</div>
                <div className='f-13 px-4 fw-500 clr-898989'>7895643256</div>
            </div>
            <div className='mt-2 pb-2'>
                <div className='f-xs fw-500 px-4'>Country</div>
                <div className='f-13 px-4  fw-500 clr-898989'>United Kingdom</div>
            </div>
            <div className='mt-2 pb-2'>
                <div className='f-xs fw-500 px-4'>State/Region</div>
                <div className='f-13 px-4  fw-500 clr-898989'>Mancheter</div>
            </div>
            <div className='mt-2 pb-2'>
                <div className='f-xs fw-500 px-4'>Postal Code</div>
                <div className='f-13 px-4 fw-500 clr-898989'>SWA1 1AA</div>
            </div>
            <div className='fw-600 f-xs mt-3 black-clr px-4 dotted-border-btm pb-2'>Secondary Address</div>
            <div className='px-4 py-3 fw-500 f-13 clr-898989'>
                Charlette
                Eleanor
                079 8198 3261
                eleanor@gmail.com
                76 Hertingfordbury Rd,TR8 7HE
                Newlyn East,United Kingdom
            </div>
            
            <div className='mt-3 dotted-border-top pt-2'>
                <div className='f-xs fw-500 px-4'>Reset Password</div>
            </div>
        </div>
        <div className='w-60-resp-mob'>
            <div className='box-shadow radius-7 p-4'>
                <div className='fw-600 f-sm mt-3 black-clr'>Orders</div>
                <div className='fw-500 f-xs my-3 black-clr'><span className='me-2'>Total Spent</span><span><i className="fa-solid fa-dollar-sign"></i></span><span className='me-1'>34,560.45</span><span className='me-1'>on</span><span className='me-1'>7</span><span>orders</span></div>
                <div className='region-table mt-3'>
                        <table class="rwd-table mb-2">
                            <tbody>
                            <tr>
                                <th className='f-12'>ORDER</th>
                                <th className='f-12'>DATE</th>
                                <th className='f-12'>STATUS</th>
                                <th className='f-12'>ITEMS</th>
                                <th className='f-12'>AMOUNT</th>
                            </tr>
                            <tr>
                                <td data-th="Supplier Numberr">
                                #80294 
                                </td>
                                <td data-th="Supplier Name">
                                18 May 2023
                                </td>
                                <td data-th="Invoice Number">
                                Pending
                                </td>
                                <td data-th="Invoice Date">
                                4
                                </td>
                                <td data-th="Due Date">
                                £ 320.00
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Numberr">
                                #80294 
                                </td>
                                <td data-th="Supplier Name">
                                18 May 2023
                                </td>
                                <td data-th="Invoice Number">
                                Pending
                                </td>
                                <td data-th="Invoice Date">
                                4
                                </td>
                                <td data-th="Due Date">
                                £ 320.00
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Numberr">
                                #80294 
                                </td>
                                <td data-th="Supplier Name">
                                18 May 2023
                                </td>
                                <td data-th="Invoice Number">
                                Pending
                                </td>
                                <td data-th="Invoice Date">
                                4
                                </td>
                                <td data-th="Due Date">
                                £ 320.00
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Numberr">
                                #80294 
                                </td>
                                <td data-th="Supplier Name">
                                18 May 2023
                                </td>
                                <td data-th="Invoice Number">
                                Pending
                                </td>
                                <td data-th="Invoice Date">
                                4
                                </td>
                                <td data-th="Due Date">
                                £ 320.00
                                </td>
                            </tr>
                            
                           
                            </tbody>
                        </table>
                    </div>
                <div className='text-center lightbrick f-xs mt-3'>View all 8 orders</div>

            </div>
            <div className='box-shadow radius-7 p-4'>
                <div className='fw-600 f-sm mt-3 black-clr'>Payment</div>
                
                <div className='mt-2 pb-2 fw-500'>
                    <div className='f-xs mb-2'><span className='me-3'>Cardholder Name :</span><span className='f-13 fw-500 clr-898989'>John Doe</span></div>
                    <div className='f-xs mb-2'><span className='me-3'>Credit Number :</span><span className='f-13 fw-500 clr-898989'>**** **** **** **** <span>4897</span></span></div>
                    <div className='f-xs mb-2'><span className='me-3'>Expiration Date :</span><span className='f-13 fw-500 clr-898989'>John Doe</span></div>
                    <div className='f-xs'><span className='me-3'>CVV :</span><span className='f-13 fw-500 clr-898989'>123</span></div>
                </div>
                <div className='mt-2 pb-2 fw-500'>
                    <div className='f-xs'>Total Paid Amount</div>
                    <div className='f-13 fw-500 clr-898989'>2 ($50.00)</div>
                </div>
                <div className='mt-2 pb-2 fw-500'>
                    <div className='f-xs'>Refunded Amount </div>
                    <div className='f-13 fw-500 clr-898989'>2 ($50.00)</div>
                </div>

            </div>
        </div>
      </div>
    )
  }
}

export default Addcustomer