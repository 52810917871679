import React, { Component } from 'react'

export class Addcoupon extends Component {
  render() {
    return (
        <div className='px-3 px-lg-5'>
        <div className='d-flex flex-column flex-md-row w-100 mb-3'>
            <div className='bg-f8f8f8 p-4 w-50-resp-mob me-3 box-shadow radius-11 mb-3 mb-md-0'>
                <div className='fw-500 clr-202020 f-14 mb-4'>Basic information</div>
                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Code</div>
                    <div><input className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>

                <div className='fw-500 clr-202020 f-14 mb-3'>Visibility</div>

                <section className="section-form mb-3">
                    <div className="form">
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="first" name="animals"></input>
                        <label for="first" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Published
                        </label>
                        </div>
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="second" name="animals"></input>
                        <label for="second" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Sheduled
                        </label>
                        </div>
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="third" name="animals" checked></input>
                        <label for="third" className="form__radio-label">
                            <span className="form__radio-btn"></span>
                            Hidden
                        </label>
                        </div>
                        
                        
                    </div>
                </section>

                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Discount value</div>
                    <div><input className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Usage limit</div>
                    <div><input className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
            </div>
            <div className='bg-f8f8f8 p-4 w-50-resp-mob box-shadow radius-11'>
                <div className='fw-500 clr-202020 f-14 mb-3'>Status</div>

                <section className="section-form">
                    <div className="form">
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="first" name="animals"></input>
                        <label for="first" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Enabled
                        </label>
                        </div>
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="second" name="animals"></input>
                        <label for="second" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Disabled
                        </label>
                        </div>
                        
                    
                        
                        
                    </div>
                </section>

                <div className='fw-500 clr-202020 f-14 mb-1 mt-4'>Schedule</div>
                <div className='fw-500 f-xxs'>Use these settings to limit the coupon expiration date.</div>

                <div className='mb-3 mt-4'>
                    <div className='f-13 fw-500 mb-1'>Start Date </div>
                    <div><input type='date' placeholder='' className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>

                <div className='mb-3 mt-4'>
                    <div className='f-13 fw-500 mb-1'>End Date </div>
                    <div><input type='date' placeholder='' className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
                
                <div className='mt-4'>
                    <button className='white-btn f-xs px-4 me-2 fw-500 mb-2 mb-lg-0'>Save</button>
                    <button className='lightbrick-btn f-xs px-3'>Cancel</button>
                </div>

              

            </div>
        </div>

       

 
      </div>
    )
  }
}

export default Addcoupon
