import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import logo from '../../images/brick-logo.svg';
import './Newinvoice.css'


export class Newinvoice extends Component {
    
  render() {
    
    return (
      <div className='newinvoice'>
        <div className='d-flex justify-content-end mb-4'>
            <button className='f-xs me-2 brick-btn px-2'><i class="fa-solid fa-paper-plane me-2"></i>Send Invoice</button>
            <button className='lightbrick-btn f-xs px-2'>Delete</button>
        </div>
        <div className='box-shadow invoiceborder radius-11 py-4'>
            <div className='mb-3 px-4'>
                <img src={logo} alt=''></img>
            </div>
                
            <div className='d-flex w-100 justify-content-between p-4'>
                <div className='w-50 f-xs clr-33363F fw-500'>
                John Smith<br></br> 123 Main StreetLondon SW1A 1AA<br></br> United Kingdom
                </div>
                <div className='w-auto ms-auto d-flex flex-column'>
                    <div className='d-flex align-items-center'>
                        <div className='fw-500 f-xs me-2 w-auto'>Invoice</div>
                        <input className='outlined-inp ms-auto w-165'></input>
                    </div>
                    <div className='d-flex align-items-center my-1'>
                        <div className='fw-500 f-xs me-2 w-auto'>Date Issued</div>
                        <input className='outlined-inp ms-auto w-165' type='date'></input>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='fw-500 f-xs me-2 w-auto'>Date Due</div>
                        <input className='outlined-inp ms-auto w-165' type='date'></input>
                    </div>
                </div>
            </div>

            <div className='d-flex w-100 justify-content-between px-4 bg-lightbrick py-4'>
                <div className='w-50 f-xs fw-500'>
                    <div className='fw-600 f-sm mb-2'>Invoice To:</div>
                    <div>
                        <select name="cars" id="cars" className='outlined-inp h-35 w-75'>
                            <option value="">Choose...</option>
                            <option value="John Doe">John Doe</option>
                            <option value="Alex Paul">Alex Paul</option>
                            <option value="Merin">Merin</option>
                        </select>
                    </div>
                    <div className='mt-3'>
                        <div className='fw-500 f-xs mb-1'>Add Address</div>
                        <textarea rows="4" className='outlined-inp w-75'></textarea>
                    </div>
                
                </div>
                <div className='d-flex w-50 flex-column'>
                <div className='fw-600 f-sm mb-2'>Bill To:</div>
                    <input className='outlined-inp w-75 h-35 mb-2' placeholder='Bank Name'></input>
                    <select name="cars" id="cars" className='outlined-inp w-75 h-35 mb-2'>
                        <option value="">Bank Country...</option>
                        <option value="John Doe">John Doe</option>
                        <option value="Alex Paul">Alex Paul</option>
                        <option value="Merin">Merin</option>
                    </select>
                    <input className='outlined-inp w-75 h-35 mb-2' type='text' placeholder='Total'></input>
                    <input className='outlined-inp w-75 h-35 mb-2' type='text' placeholder='IBAN'></input>
                    <input className='outlined-inp w-75 h-35' type='text' placeholder='SWIFT code'></input>
                </div>
            </div>

            <div className='w-100 px-4 py-4 w-100'>
            <div className='d-flex justify-content-between mb-3 w-100'>
                <input className='outlined-inp h-35 w-25 me-2' placeholder='ITEM'></input>
                <input className='outlined-inp h-35 w-25 me-2' placeholder='QTY'></input>
                <input className='outlined-inp h-35 w-25 me-2' placeholder='PRICE'></input>
                <input className='outlined-inp h-35 w-25' placeholder='TOTAL'></input>
            </div>
            <div>
                <textarea rows="5" className='outlined-inp py-2 w-50' placeholder='DESCRIPTION'></textarea>
            </div>

            </div>


        </div>
      </div>
    )
  }
}

export default Newinvoice