import React, { Component } from 'react'
import './Invoice.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';

export class Invoice extends Component {
  componentDidMount() {
    $("#search").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        return true
      });
    });
  }
  render() {
    return (
      <div className='px-0 px-md-4 py-3 invoice customer'>
        <div className='text-end'>
            {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
            <Link to='/Layout2/Maintanance' className='lightbrick-btn f-xs px-3 add-prod-link'><i class="fa-solid fa-scroll me-1"></i>New Invoice</Link>
        </div>

        <div className='d-flex align-items-center my-3'>
            <div className='relative w-100'>
                <input className='nav-search-inp w-100 fw-600' placeholder='Start typing search for coupon' id='search'></input>
                <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
            </div>
        </div>

        <div className='product-table tbl_scroll'>
                    <table>
                        <thead>
                            <tr className='bg-white'>
                                <th className='fw-600 f-13'>Invoice No</th>
                                <th className='fw-600 f-13'>Issued Date</th>
                                <th className='fw-600 f-13'>Customer</th>
                                <th className='fw-600 f-13'>Email</th>
                                <th className='fw-600 f-13'>Subtotal</th>
                                <th className='fw-600 f-13'>Transit</th>
                                <th className='fw-600 f-13'>Tax</th>
                                <th className='fw-600 f-13'>Discount</th>
                                <th className='fw-600 f-13'>Action</th>
                            </tr>
                        </thead>
                        <tbody id='table'>
                           
                            <tr>
                                <td className='fw-500 f-xs brick-clr fw-600'>#INV-001</td>
                                <td className='fw-500 f-xs'>May 1, 2023</td>
                                <td className='fw-500 f-xs'>John Doe</td>
                                <td className='fw-500 f-xs'>johndoe@email.com</td>
                                <td className='fw-500 f-xs'>£50.00</td>
                                <td className='fw-500 f-xs'>£10.00</td>
                                <td className='fw-500 f-xs'>£0.00</td>
                                <td className='fw-500 f-xs'>£3.50</td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs brick-clr fw-600'>#INV-001</td>
                                <td className='fw-500 f-xs'>May 1, 2023</td>
                                <td className='fw-500 f-xs'>John Doe</td>
                                <td className='fw-500 f-xs'>johndoe@email.com</td>
                                <td className='fw-500 f-xs'>£50.00</td>
                                <td className='fw-500 f-xs'>£10.00</td>
                                <td className='fw-500 f-xs'>£0.00</td>
                                <td className='fw-500 f-xs'>£3.50</td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs brick-clr fw-600'>#INV-001</td>
                                <td className='fw-500 f-xs'>May 1, 2023</td>
                                <td className='fw-500 f-xs'>John Doe</td>
                                <td className='fw-500 f-xs'>johndoe@email.com</td>
                                <td className='fw-500 f-xs'>£50.00</td>
                                <td className='fw-500 f-xs'>£10.00</td>
                                <td className='fw-500 f-xs'>£0.00</td>
                                <td className='fw-500 f-xs'>£3.50</td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs brick-clr fw-600'>#INV-001</td>
                                <td className='fw-500 f-xs'>May 1, 2023</td>
                                <td className='fw-500 f-xs'>John Doe</td>
                                <td className='fw-500 f-xs'>johndoe@email.com</td>
                                <td className='fw-500 f-xs'>£50.00</td>
                                <td className='fw-500 f-xs'>£10.00</td>
                                <td className='fw-500 f-xs'>£0.00</td>
                                <td className='fw-500 f-xs'>£3.50</td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs brick-clr fw-600'>#INV-001</td>
                                <td className='fw-500 f-xs'>May 1, 2023</td>
                                <td className='fw-500 f-xs'>John Doe</td>
                                <td className='fw-500 f-xs'>johndoe@email.com</td>
                                <td className='fw-500 f-xs'>£50.00</td>
                                <td className='fw-500 f-xs'>£10.00</td>
                                <td className='fw-500 f-xs'>£0.00</td>
                                <td className='fw-500 f-xs'>£3.50</td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            

                        </tbody>
                    </table>
                </div>
      </div>
    )
  }
}

export default Invoice