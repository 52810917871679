import React, { Component } from 'react'

export class Userprofsetting extends Component {
  render() {
    return (
      <div>
        <div className='w-100 d-flex flex-column flex-lg-row mt-2 mt-lg-5'>
            <div className='w-50-resp-mob me-3 bg-white box-shadow radius-11 p-3 mb-3 mb-lg-0'>
                <div className='brick-clr fw-600 mb-3'>Profile</div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='fw-500 f-xs'>Edit Profile </div>
                    {/* <button className='white-btn px-3'><i class="fa-solid fa-pencil"></i></button> */}
                </div>
                <div className='w-100 d-flex mb-3'>
                    <div className='w-50 me-2'>
                        <div className='f-13 clr-898989 mb-1'>First Name</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='Charlotte'></input>
                    </div>
                    <div className='w-50'>
                        <div className='f-13 clr-898989 mb-1'>Last Name</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='Elanor'></input>
                    </div>
                </div>
                <div className='w-100 d-flex mb-3'>
                    <div className='w-50 me-2'>
                        <div className='f-13 clr-898989 mb-1'>Phone Number</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='079 8198 3261'></input>
                    </div>
                    <div className='w-50'>
                        <div className='f-13 clr-898989 mb-1'>Email</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='eleanor@gmail.com'></input>
                    </div>
                </div>
                
            </div>
            <div className='w-50-resp-mob bg-white box-shadow radius-11 p-3'>
                <div className='brick-clr fw-600 mb-3'>Address</div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className='fw-500 f-xs'>Edit Address</div>
                    {/* <button className='white-btn px-3'><i class="fa-solid fa-pencil"></i></button> */}
                </div>
                <div className='w-100 d-flex mb-3'>
                    <div className='w-50 me-2'>
                        <div className='f-13 clr-898989 mb-1'>Address/Street</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='76 Hertingfordbury Rd'></input>
                    </div>
                    <div className='w-50'>
                        <div className='f-13 clr-898989 mb-1'>Postal Code</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='TR8 7HE'></input>
                    </div>
                </div>
                <div className='w-100 d-flex mb-3'>
                    <div className='w-50 me-2'>
                        <div className='f-13 clr-898989 mb-1'>City/Town</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='Newlyn East'></input>
                    </div>
                    <div className='w-50'>
                        <div className='f-13 clr-898989 mb-1'>Coutry</div>
                        <input className='inp-D9D9D9 f-xs fw-500 w-100' value='United Kingdom'></input>
                    </div>
                </div>
                <div className='w-100 d-flex mb-3'>
                    <div className='w-50 me-2'>
                        <div className='f-13 clr-898989 mb-1'>Address Type</div>
                        <select name="addresstype" id="addresstype" className='inp-D9D9D9 f-xs fw-500 w-100'>
                            <option value="Home">Home</option>
                            <option value="Work">Work</option>
                        </select>
                    </div>
                </div>
            </div>
            

        </div>
        <div className='d-flex justify-content-end mt-3'>
                <button className='f-xs fw-500 lightbrick-btn px-4 me-3'>Update</button>
                <button className='f-xs fw-500 white-btn px-4'>Cancel</button>
                </div>
      </div>
    )
  }
}

export default Userprofsetting