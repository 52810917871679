import React, { Component } from 'react'
import './Adminsettings.css'

export class Adminsettings extends Component {
  render() {
    return (
      <div className='adminsettings'>
        <div className='fw-600 f-md'>General</div>
        <div className='w-100 d-flex flex-column flex-md-row align-items-center mb-3'>
            <div className='w-40-resp text-center'>
                <div className="wrapper">
                        <div className="box">
                            <div className="js--image-preview"></div>
                            <div className="upload-options">
                            <label>
                                <input type="file" className="image-upload" value='' accept="image/*" />
                            </label>
                            </div>
                        </div>
                </div>
                <div className='fw-500 f-xxs mt-1'>
                    Allowed *.jpeg, *.jpg, *.png, *.gif <br></br>
                    max size of 3.1 MB
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <div className='fw-500 f-xs me-2'>Public Profile</div>
                    <div>
                        <input type="checkbox" id="toggle-btn" className='toggle-btn'></input>
                        <label for="toggle-btn" className='toggle-btn-label'></label>
                    </div>
                </div>
            </div>
            <div className='w-60-resp d-flex flex-column flex-md-row'>
                <div className='w-50-resp me-3'>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Name</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Phone Number</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>City</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Country</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                </div>
                <div className='w-50-resp'>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Email Address</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Website Address</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>State/Region</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2'>
                        <div className='f-xs fw-500 black-clr mb-1'>Zip code</div>
                        <div><input className='inp-D9D9D9 w-100'></input></div>
                    </div>
                    <div className='mb-2 text-end mt-3'>
                        <button className='lightbrick-btn px-3 f-xs'>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-100 d-flex flex-column flex-md-row'>
            <div className='w-50-resp me-4'>
                <div className='fw-500 f-sm mb-3'>Change Password</div>
                <div>
                    <div className='mb-3'><input type="password" name="password" className='inp-D9D9D9 w-100 f-xs' placeholder='Old Password'></input></div>
                    <div className='mb-1'><input type='password' className='inp-D9D9D9 w-100 f-xs' placeholder='New Password'></input></div>
                    <div className='f-xxs fw-500 brick-clr'>Password must be minimum 6+</div>
                    <div className='text-end mb-3'>
                        <button className='plain-lightbrick-btn f-xs px-4'>Send email</button>
                    </div>
                    <div className='mb-1'><input type='password' className='inp-D9D9D9 w-100 f-xs' placeholder='Confirm Your New Password'></input></div>
                    <div className='mb-2 text-end mt-3'>
                        <button className='lightbrick-btn px-3 f-xs'>Save Changes</button>
                    </div>
                </div>
                
            </div>
            <div className='w-50-resp'>
                <div className='fw-500 f-sm mb-3'>Notifications</div>
                <div className='f-xxs'>ACTIVITY</div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn1" className='toggle-btn'></input>
                        <label for="toggle-btn1" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Email me when someone comments the products</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn2" className='toggle-btn'></input>
                        <label for="toggle-btn2" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Allow all notification</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn3" className='toggle-btn'></input>
                        <label for="toggle-btn3" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Email me when someone logined</div>
                </div>
                <div className='f-xxs my-2'>APPLICATION</div>

                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn4" className='toggle-btn'></input>
                        <label for="toggle-btn4" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Weekly product updates</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn5" className='toggle-btn'></input>
                        <label for="toggle-btn5" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Monthly product reviews</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn6" className='toggle-btn'></input>
                        <label for="toggle-btn6" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Invoice product purchased updates</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn7" className='toggle-btn'></input>
                        <label for="toggle-btn7" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Customers reviews </div>
                </div>
                <div className='mb-2 text-end mt-3'>
                        <button className='lightbrick-btn px-3 f-xs'>Save Changes</button>
                    </div>
            </div>
        </div>
      </div>
    );
  }
  
  componentDidMount(){
    setTimeout(() => {
        this.initNavAnim();
    }, 500);
  }
  initNavAnim(){
    function initImageUpload(box) {
        let uploadField = box.querySelector('.image-upload');
      
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
        
        function previewImage(file){
          let thumb = box.querySelector('.js--image-preview'),
              reader = new FileReader();
      
          reader.onload = function() {
            thumb.style.backgroundImage = 'url(' + reader.result + ')';
          }
          reader.readAsDataURL(file);
          thumb.className += ' js--no-default';
        }
      
        function checkType(file){
          let imageType = /image.*/;
          if (!file.type.match(imageType)) {
            console.log('Datei ist kein Bild') ;
          } else if (!file){
            console.log('Kein Bild gewählt') ;
          } else {
            previewImage(file);
          }
        }
        
      }
      
      // initialize box-scope
      var boxes = document.querySelectorAll('.box');
      
      for (let i = 0; i < boxes.length; i++) {
        let box = boxes[i];
        initDropEffect(box);
        initImageUpload(box);
      }
      
      
      
      /// drop-effect
      function initDropEffect(box){
        let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
        
        // get clickable area for drop effect
        area = box.querySelector('.js--image-preview');
        area.addEventListener('click', fireRipple);
        
        function fireRipple(e){
          area = e.currentTarget
          // create drop
          if(!drop){
            drop = document.createElement('span');
            drop.className = 'drop';
            this.appendChild(drop);
          }
          // reset animate class
          drop.className = 'drop';
          
          // calculate dimensions of area (longest side)
          areaWidth = getComputedStyle(this, null).getPropertyValue("width");
          areaHeight = getComputedStyle(this, null).getPropertyValue("height");
          maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));
      
          // set drop dimensions to fill area
          drop.style.width = maxDistance + 'px';
          drop.style.height = maxDistance + 'px';
          
          // calculate dimensions of drop
          dropWidth = getComputedStyle(this, null).getPropertyValue("width");
          dropHeight = getComputedStyle(this, null).getPropertyValue("height");
          
          // calculate relative coordinates of click
          // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
          x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
          y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
          
          // position drop and animate
          drop.style.top = y + 'px';
          drop.style.left = x + 'px';
          drop.className += ' animate';
          e.stopPropagation();
          
        }
      }
      
  }
}

export default Adminsettings
