import React, { Component } from 'react'
import './Footer.css'
import Logo from '../../images/Footer/Logo.svg'
import { Link } from 'react-router-dom'

export class Footer extends Component {
  render() {
    return (
      <div className='w-100 d-flex flex-column flex-lg-row justify-content-between mt-5'>
        <div className='w-40-resp-mob text-center text-lg-start'>
          <img src={ Logo } alt='' className='logosize'></img>
          <div className='f-xs clr-868790 fw-500 mt-2 mb-3'>We are one of the best & quality<br></br>full in market. Let's join.</div>
          <div className='f-address d-flex flex-column align-items-center align-items-lg-start'>
            <div className='d-flex'>
              <div className='me-4'><span className="material-symbols-outlined">distance</span></div>
              <div className='f-xs fw-500 black-clr'>
                5 Seafield Place, Formby<br></br>
                L37 4AW, United Kingdom.
              </div>
            </div>
            <div className='d-flex my-3'>
              <div className='me-4'><span className="material-symbols-outlined">call</span></div>
              <div className='f-xs fw-500 black-clr'>077 3922 5112</div>
            </div>
            <div className='d-flex'>
            <div className='me-4'><span className="material-symbols-outlined">language</span></div>
              <div className='f-xs fw-500 black-clr'>sibufishandmeat.co.uk</div>
            </div>
          </div>
        </div>
        <div className='w-20-resp-mob text-center text-lg-start black-clr f-links mt-4'>
          <div className='fw-600 f-md mb-2'>Services</div>
          <div className=''>
            <div className='mb-2'><Link href='#'>My Account</Link></div>
            <div className='mb-2'><Link href='#'>Tracking Order</Link></div>
            <div className='mb-2'><Link href='#'>Customer Services</Link></div>
            <div className='mb-2'><Link href='#'>Compare</Link></div>
            <div className='mb-2'><Link href='#'>Wishlist</Link></div>
            <div><Link href='#'>Privacy Policy</Link></div>
          </div>
        </div>
        <div className='w-20-resp-mob text-center text-lg-start black-clr f-links mt-4'>
          <div className='fw-600 f-md mb-2'>Information</div>
          <div className=''>
            <div className='mb-2'><Link href='#'>Home</Link></div>
            <div className='mb-2'><Link href='#'>About Us</Link></div>
            <div className='mb-2'><Link href='#'>Categories</Link></div>
            <div className='mb-2'><Link href='#'>Testimonials</Link></div>
            <div className='mb-2'><Link href='#'>Team</Link></div>
          </div>
        </div>
        <div className='w-20-resp-mob text-center text-lg-start followus mt-4'>
          <div className='fw-600 f-md mb-2'>Follow Us</div>
          <div className='f-xs clr-868790 fw-500 mt-2 mb-3 px-4 px-lg-0'>We are one of the best & quality full in market. Let's join.</div>
          <div className='w-100 text-center relative'>
            <input className='w-100 radius-7 f-xxs fw-500' placeholder='Enter email'></input>
            <button className='absolute r-0 lightbrick-btn px-3 h-100 f-xs'><i class="fa-solid fa-paper-plane"></i></button>
          </div>
          <div className='d-flex justify-content-center justify-content-lg-start mt-4'>
            <div className='socialmedia me-2'>
              <Link href="#" className=''><i className="fa-brands fa-twitter"></i></Link>
            </div>
            <div className='socialmedia me-2'>
              <Link href="#" className=''><i className="fa-brands fa-whatsapp"></i></Link>
            </div>
            <div className='socialmedia me-2'>
              <Link href="#" className=''><i className="fa-brands fa-facebook-f"></i></Link>
            </div>
            <div className='socialmedia'>
              <Link href="#" className=''><i className="fa-brands fa-instagram"></i></Link>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Footer