import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
import Navbar from '../Navbar/Navbar'
import './Layout2.css';
import Toolbar from '../Toolbar/Toolbar';
import BrickLogo from '../../images/brick-logo.svg'

export class layout2 extends Component {
  // constructor(props) {
  //   super(props);
  //   this.handleClick = this.handleClick.bind(this);
  // }
  myFunction() {
    document.getElementsByClassName("navbar-left")[0].classList.toggle("open");
  }
  render() {

    
    return (
      <React.Fragment>
         <div className='bg-white resp-toolbar d-block d-lg-none d-flex'>
            <div>
              <img src={BrickLogo} alt=''></img>
            </div>
            <button onClick={this.myFunction} className='white-btn px-3 hamburger-btn'><i className="fa-solid f-lg fa-bars"></i></button>

          </div>
        <div className='w-100 d-flex ff-mon'>
         

          <div className='navbar-left bg-darknavy div1'>
          {/* <button onClick={() => this.handleClick()}>Click Me</button> */}
            <Navbar></Navbar>
          </div>
          <div className='content_wrpr d-flex flex-column'>
            <Toolbar></Toolbar>
            <div className='w-100 d-flex flex-column py-4 px-2 px-lg-4'>
              <Outlet />
            </div>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default layout2