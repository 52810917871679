import React, { Component } from 'react'
import './Catalog.css'
import img1 from '../../images/meat.jpg'
import fish from '../../images/fish.jpg'
import shellfish from '../../images/shellfish.jpg'
import { Link } from 'react-router-dom'


export class Catalog extends Component {
  render() {
    return (
      <div className='px-3 px-md-4 py-3 coupon catalog customer'>
        <div className='text-end'>
            {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
            <Link to='/Layout2/Notification' className='lightbrick-btn f-xs px-4 add-prod-link me-2'>Notification Centre</Link>

            <Link to='/Layout2/Addproduct' className='lightbrick-btn f-xs px-4 add-prod-link'>New Catalog</Link>
        </div>

        <div className='d-flex align-items-center my-3'>
            <div className='relative w-100'>
                <input className='nav-search-inp w-100 fw-600' placeholder='Start typing search for coupon' id='search'></input>
                <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
            </div>
        </div>

        <div className='w-100 product-table'>
          <table className="rwd-table mb-2 catalog-table">
            <tbody id='table'>
              <tr>
                  <th>Product ID</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Category</th>
                  <th>Stock</th>
                  <th>Price</th>
                  <th className='text-center'>More</th>
              </tr>
              <tr>
                  <td data-th="Product ID" className='f-xs'>
                  7710
                  </td>
                  <td data-th="Name" className='f-xs'>
                  Fresh prawns
                  </td>
                  <td data-th="Image">
                  <img src={img1} alt=''></img>
                  </td>
                  <td data-th="Category" className='f-xs'>
                  Meat
                  </td>
                  <td data-th="Stock">
                    <div className='status green f-12 fw-600'>In Stock</div>
                  
                  </td>
                  <td data-th="Price" className='f-xs'>
                  £ 15.3                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Product ID" className='f-xs'>
                  7710
                  </td>
                  <td data-th="Name" className='f-xs'>
                  Fresh prawns
                  </td>
                  <td data-th="Image">
                  <img src={fish} alt=''></img>
                  </td>
                  <td data-th="Category" className='f-xs'>
                  Fish
                  </td>
                  <td data-th="Stock">
                    <div className='status red f-12 fw-600'>Out of Stock</div>
                  
                  </td>
                  <td data-th="Price" className='f-xs'>
                  £ 15.3                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Product ID" className='f-xs'>
                  7710
                  </td>
                  <td data-th="Name" className='f-xs'>
                  Fresh prawns
                  </td>
                  <td data-th="Image">
                  <img src={shellfish} alt=''></img>
                  </td>
                  <td data-th="Category" className='f-xs'>
                  Fish
                  </td>
                  <td data-th="Stock">
                    <div className='status grey f-12 fw-600'>Preorder</div>
                  
                  </td>
                  <td data-th="Price" className='f-xs'>
                  £ 15.3                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Product ID" className='f-xs'>
                  7710
                  </td>
                  <td data-th="Name" className='f-xs'>
                  Wild Pork Diced
                  </td>
                  <td data-th="Image">
                  <img src={img1} alt=''></img>
                  </td>
                  <td data-th="Category" className='f-xs'>
                  Meat
                  </td>
                  <td data-th="Stock">
                    <div className='status green f-12 fw-600'>In Stock</div>
                  
                  </td>
                  <td data-th="Price" className='f-xs'>
                  £ 15.3                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Product ID" className='f-xs'>
                  7710
                  </td>
                  <td data-th="Name" className='f-xs'>
                  Fresh prawns
                  </td>
                  <td data-th="Image">
                  <img src={fish} alt=''></img>
                  </td>
                  <td data-th="Category" className='f-xs'>
                  Fish
                  </td>
                  <td data-th="Stock">
                    <div className='status red f-12 fw-600'>Out of Stock</div>
                  
                  </td>
                  <td data-th="Price" className='f-xs'>
                  £ 15.3                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
            
           
             
            </tbody>
          </table> 
        </div>
      </div>
    )
  }
}

export default Catalog