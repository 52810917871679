import React, { Component } from 'react'
import img3 from '../../images/Topselling/topsellingfish2.jpg';
import './Userorderpage.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export class Userorderpage extends Component {
  render() {
    return (
      <div className='userorderpage'>
        <div className='w-100 banner-img mb-5'>
            <div className='lh-33 mb-3'>
            <b className='f-40 black-clr'>Discover the best in </b><br></br>
            <span className='brick-clr f-40 ff-carousaly'><b>fresh meats and</b></span><br></br>
            <span className='brick-clr f-40 ff-carousaly' me-2><b>seafood</b></span><span className='f-40 black-clr ms-3'><b>at our shop</b></span>
            </div>

            <div className='f-sm clr-868790 fw-500'>
            Lorem ipsum dolor sit amet. Vel illum maiores hic<br></br> dolore qui reprehenderit odit aut dolores illum.
            </div>

            <button className='lightbrick-btn mt-3'>Show Now <i className="fa-solid fa-chevron-right"></i></button>
        </div>
        <div className='w-100 d-flex'>
            <div className='w-50 me-3 bg-extralightbrick lightbrick text-center fw-500 py-2 radius-7'>Your Orders</div>
            <div className='w-50 lightbrick bg-white fw-500 text-center py-2 radius-7'>Tracking Order</div>
        </div>
        <div className='yourorders w-75-resp-mob mt-4 mx-0 mx-lg-auto'>
                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
               

                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
               
                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
               
                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
               
                <div className='d-flex flex-column flex-lg-row w-100 bg-white  align-items-center box-shadow radius-7 p-3 mb-3'>
                    <img src={img3} alt='' className='prodimg me-0 me-lg-3 mb-2 mb-lg-0'></img>
                    <div className='w-50-resp-mob text-center text-lg-start mb-3 mb-lg-0'>
                        <div className='fw-500 f-xs mb-2'>beef shoulder chunks diced</div>
                        <div><span className='bg-F7EBEC brick-clr me-3 p-2 radius-7 f-xs fw-600 px-3'>£15/box</span><span className='bg-FOFOFO fw-500 p-2 f-xs radius-7 px-3'>2kg (1bag)</span></div>
                    </div>
                    <div className='d-flex'>
                      <button className='lightbrick-btn fw-500 f-13 me-2 px-3' data-toggle="modal" data-target="#exampleModal">Cancel Order</button>
                        <button className='brick-btn fw-500 f-13 px-4' data-toggle="modal" data-target="#trackOrder">Track Order</button>
                    </div>
                </div>
               
               
                
        </div>


             {/* Popup Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-sm" role="document">
    <div className="modal-content">
     
      <div className="modal-body bg-F9F9F9 text-center">
        <div className='bg-gradlightbrick m-auto mb-2 w-h-100 radius-50 d-flex justify-content-center align-items-center'><i class="fa-solid fa-cart-shopping fa-3x white-clr"></i></div>
        <div className='fw-500 f-xs'>Are you sure you want send a cancellation request?</div>
        <div className='f-xxs clr-565B67 fw-500'>Your request will be sent to our team</div>
        <button className='f-xs plain-lightbrick-btn w-100 mt-3'>Proceed</button>
        <button className='f-xs lightbrick-btn w-100 mt-2'><i class="fa-solid fa-trash-can me-2"></i>Delete</button>
        <VerticalTimeline>
  
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="15:20 PM"
    iconStyle={{ background: '#E05A67', color: '#fff' }}
  >
    <div className="vertical-timeline-element-title f-xxs fw-500">Order in Placed-Feb 14</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
   
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="16:20 PM"
    iconStyle={{ background: '#E05A67', color: '#fff' }}
    
  >
    <div className="vertical-timeline-element-title f-xxs fw-500">Orders are Transit- Feb 14</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="11:20 AM"
    iconStyle={{ background: '#E05A67', color: '#fff' }}
    
  >
    <div className="vertical-timeline-element-title f-xxs fw-500">Order Canceled -Feb 15</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
  </VerticalTimelineElement>
 

        </VerticalTimeline>
      <div>

        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="trackOrder" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body radius-11 bg-F9F9F9 text-center">
        <div className='fw-500 f-xs mb-2 bb-dashed pb-2'>Order Status Details</div>
        <VerticalTimeline>
  
  <VerticalTimelineElement
  className="vertical-timeline-element--work"
  date=""
  iconStyle={{ background: '#E05A67', color: '#fff' }}
  
>
    <div className="vertical-timeline-element-title f-xxs fw-500">Order in Placed-Feb 14</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>
   
  </VerticalTimelineElement>
  <VerticalTimelineElement
  className="vertical-timeline-element--work"
  date=""
  iconStyle={{ background: '#E05A67', color: '#fff' }}
  
>
    <div className="vertical-timeline-element-title f-xxs fw-500">Orders are Transit- Feb 14</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date=""
    iconStyle={{ background: '#E05A67', color: '#fff' }}
    
  >
    <div className="vertical-timeline-element-title f-xxs fw-500">Order Canceled -Feb 15</div>
    <div className="vertical-timeline-element-subtitle f-xxs clr-898989">32 Manchester Ave Ringgold, GA 30736</div>
    <div className="vertical-timeline-element-subtitle f-xxs black-clr fw-600">15:20 PM</div>

  </VerticalTimelineElement>
 

        </VerticalTimeline>
      <div>

        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    )
  }
}

export default Userorderpage