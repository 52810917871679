// import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";
import Registration from './components/Registration/Registration';
import Layout1 from './Layouts/Layout1/Layout1';
import Layout2 from './Layouts/Layout2/Layout2';
import Layout4 from './Layouts/Layout4/Layout4';
import Login from './components/Login/Login';
import Overview from './components/Overview/Overview';
import Location from './components/Location/Location';
import Catalog from './components/Catalog/Catalog';
import Addproduct from './components/Addproduct/Addproduct';
import Customer from './components/Customer/Customer';
import Addcustomer from './components/Addcustomer/Addcustomer';
import Orders from './components/Orders/Orders';
import Orderdetails from './components/Orderdetails/Orderdetails';
import Coupon from './components/Coupon/Coupon';
import Invoice from './components/Invoice/Invoice';
import Newinvoice from './components/Newinvoice/Newinvoice';
import Viewinvoice from './components/Viewinvoice/Viewinvoice';
import Topsellingfish from './components/Topsellingfish/Topsellingfish';
import Customerreview from './components/Customerreview/Customerreview';
// import UserToolbar from './Layouts/UserToolbar/UserToolbar';
import Layout5 from './Layouts/Layout5/Layout5';
import Userhome from './components/Userhome/Userhome';
import Aboutus from './components/Aboutus/Aboutus';
import Catoffer from './components/Catoffer/Catoffer';
import Adminsettings from './components/Adminsettings/Adminsettings';
import Sales from './components/Sales/Sales';
import Error from './components/Error/Error';
import Filemanager from './components/Filemanager/Filemanager';
import Analytics from './components/Analytics/Analytics';
import Layout3 from './Layouts/Layout3/Layout3';
import Newuser from './components/Newuser/Newuser';
import Ecommerce from './components/Ecommerce/Ecommerce';
import Maintanance from './components/Maintanance/Maintanance';
import Createsales from './components/Createsales/Createsales';
import Purchase from './components/Purchase/Purchase';
import Addcoupon from './components/Addcoupon/Addcoupon';
import Createpurchase from './components/Createpurchase/Createpurchase';
import Addcart from './components/Addcart/Addcart';
import Notification from './components/Notification/Notification';
import Notificationhistory from './components/Notificationhistory/Notificationhistory';
import Checkout from './components/Checkout/Checkout';
import Cartaddress from './components/Cartaddress/Cartaddress';
import Cartpayment from './components/Cartpayment/Cartpayment';
import Userorderpage from './components/Userorderpage/Userorderpage';
import Userprofsetting from './components/Userprofsetting/Userprofsetting';
import Faq from './components/FAQ/Faq';
import Catfish from './components/Catfish/Catfish';
import Catmeat from './components/Catmeat/Catmeat';
import Paymentoptions from './components/Paymentoptions/Paymentoptions';

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Layout1 />}>
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
        </Route>

        <Route exact path="/layout2" element={<Layout2 />}>
          <Route path="overview" element={<Overview />} />
          <Route path="location" element={<Location />} />
          <Route path="catalog" element={<Catalog />} />
          <Route path="addproduct" element={<Addproduct />} />
          <Route path="customer" element={<Customer />} />
          <Route path="addcustomer" element={<Addcustomer />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orderdetails" element={<Orderdetails />} />
          <Route path="coupon" element={<Coupon />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="newinvoice" element={<Newinvoice />} />
          <Route path="viewinvoice" element={<Viewinvoice />} />
          <Route path="topxellingfish" element={<Topsellingfish />} />
          <Route path="adminsettings" element={<Adminsettings />} />
          <Route path="sales" element={<Sales />} />
          <Route path="error" element={<Error />} />
          <Route path="maintanance" element={<Maintanance />} />
          <Route path="filemanager" element={<Filemanager />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="ecommerce" element={<Ecommerce />} />
          <Route path="createsales" element={<Createsales />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="createpurchase" element={<Createpurchase />} />
          <Route path="addcoupon" element={<Addcoupon />} />
          <Route path="notification" element={<Notification />} />
          <Route path="notificationhistory" element={<Notificationhistory />} />
        </Route>

        <Route exact path="/layout3" element={<Layout3 />}>
          <Route path="newuser" element={<Newuser />} />
        </Route>

        <Route exact path="/layout4" element={<Layout4 />}>
          <Route path="topsellingfish" element={<Topsellingfish />} />
          <Route path="customerreview" element={<Customerreview />} />
        </Route>

        <Route exact path="/layout5" element={<Layout5 />}>
          <Route path="userhome" element={<Userhome />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="catoffer" element={<Catoffer />} />
          <Route path="addcart" element={<Addcart />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="cartaddress" element={<Cartaddress />} />
          <Route path="cartpayment" element={<Cartpayment />} />
          <Route path="userorderpage" element={<Userorderpage />} />
          <Route path="userprofsetting" element={<Userprofsetting />} />
          <Route path="catfish" element={<Catfish />} />
          <Route path="catmeat" element={<Catmeat />} />
          <Route path="faq" element={<Faq />} />
          <Route path="paymentoptions" element={<Paymentoptions />} />

        </Route>


        
      </Routes>
    </div>
  );
}

export default App;
