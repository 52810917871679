import React, { Component } from 'react'
import ShibuNavbarLogo from '../../images/Userpanel/Toolbar/SibuLogo.svg';
import './UserToolbar.css'
import { Link } from 'react-router-dom'
import userimg from '../../images/userimg.png'
import paypal from '../../images/Utoolbar/paypal.svg'
import apple from '../../images/Utoolbar/apple-logo.svg'
import google from '../../images/Utoolbar/google.svg'
import upi from '../../images/Utoolbar/UPI.svg'
// import profileimg from '../../images/profile/profile-img.jfif';
// import { Link } from 'react-router-dom'

export class UserToolbar extends Component {
  toggleCategory() {
    var x = document.getElementById("categoriespopuo");
    var y = document.getElementById("categoriespopupmini");
    if (x.style.display === "none" || y.style.display === "none") {
      x.style.display = "block";
      y.style.display = "block";
    } else {
      x.style.display = "none";
      y.style.display = "none";
    }
  
}
  toggleSettings() {
    var x = document.getElementById("popupboxsettings");
    var y = document.getElementById("popupboxsettingmini");
    if (x.style.display === "none" || y.style.display === "none") {
      x.style.display = "block";
      y.style.display = "block";
    } else {
      x.style.display = "none";
      y.style.display = "none";
    }
  
}
  render() {
    return (
      <div className='mb-2 adminsettings'>
        <div className='d-none d-lg-block'>
          <div className='d-flex w-100 justify-content-between u-tooblbar align-items-center mb-3'>
              <div className='d-flex align-items-center relative'>
                <div>
                  <img src={ShibuNavbarLogo} alt=''></img>
                </div>
                <div className='d-flex align-items-center ms-4 me-2'>
                          {/* <div className='black-clr me-4'><b>Overview</b></div> */}
                          <div className='relative'>
                              <input className='nav-search-inp' placeholder='Search'></input>
                              <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
                          </div>
                      </div>
                <div className='fw-600 f-xs d-flex align-items-center'  onClick={this.toggleCategory}><span class="material-symbols-outlined f-30 me-2 clr-33363F">apps</span>Categories</div>
                <div className='bg-white radius-11 box-shadow absolute categoriespopuo p-3' id='categoriespopuo' onClick={this.toggleCategory}>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems' to="/Layout5/Catfish"><i class="fa-solid fa-fish me-2 f-lg"></i>Fish</Link></div>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems' to="/Layout5/Catmeat"><i class="fa-solid fa-drumstick-bite me-2 f-lg"></i>Meat</Link></div>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems'><i class="fa-solid fa-shrimp me-2 f-lg"></i>Seafood</Link></div>
                   <div><Link className='clr-868790 fw-500 brick-clr catitems'  to="/Layout5/Catoffer"><i class="fa-solid fa-tags me-2 f-lg"></i>Best Offers</Link></div>
                </div>
              </div>
              <div className='d-flex align-items-baseline'>
                
                <div className='relative'>
                  <button className='mx-1'>
                  <i class="fa-solid fa-cart-shopping f-lg clr-33363F"></i>
                  </button>
                  <div className='absolute position-middle-r'><i class="fa-solid fa-circle lightbrick f-8"></i></div>
                </div>

                <div className='relative'>
                  <button className='mx-1'>
                  <i class="fa-regular fa-heart f-lg clr-33363F"></i>
                  </button>
                  <div className='absolute position-middle-r'><i class="fa-solid fa-circle lightbrick f-8"></i></div>
                </div>

                <div>
                <button className='f-xs fw-500 d-flex align-items-center' onClick={this.toggleSettings}>
                <i class="fa-regular fa-user f-lg me-2 clr-33363F"></i>Kelly<i class="fa-solid fa-chevron-down f-xs ms-1"></i>
                </button>
                <div id='popupboxsettings'>
                  <div className='d-flex flex-column bg-white radius-11 popupboxsettings box-shadow absolute pt-4'>
                    <div className='px-4 mb-3 d-flex'>
                      <div className='me-2'>
                        <img src={userimg} alt='' className='popupdataimg'></img>
                      </div>
                      <div>
                      <div className='fw-500 f-xs'>Charlette Eleanor</div>
                      <div className='f-xxs clr-868790'>elanor@untitledui.com</div>
                      </div>
                    </div>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' to='/Layout5/Userprofsetting'><i class="fa-regular fa-user me-2"></i>Edit Profile</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' to='/Layout5/Cartaddress'><i class="fa-solid fa-location-dot me-2"></i>Address</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' data-toggle="modal" data-target="#exampleModal"><i class="fa-regular fa-bell me-2"></i>Notification</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' to='/Layout5/Userorderpage'><i class="fa-solid fa-bag-shopping me-2"></i>Order</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' data-toggle="modal" data-target="#exampleModalPayment"><i class="fa-regular fa-credit-card me-2"></i>Payment</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2'  data-toggle="modal" data-target="#exampleModalSecurity"><i class="fa-solid fa-shield-halved me-2"></i>Security</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' data-toggle="modal" data-target="#exampleModalPrivacy"><i class="fa-solid fa-lock me-2"></i>Privacy Policy</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-4 mb-2' to='/Layout5/Faq'><i class="fa-regular fa-circle-question me-2"></i>Help Center</Link>
                    <Link className='onhoverlightbrick logout bg-lightbrick white-clr fw-500 px-4 mb-2 text-center py-2 mb-0 mt-3 underline-none'><i class="fa-solid fa-arrow-right-from-bracket me-2"></i>Logout</Link>
                  </div>
                </div>
                </div>
                
                
              </div>
          </div>
        </div>

        <div className='d-block d-lg-none userpaneltopnav miniversion'>
            <div className='d-flex justify-content-between mb-2'>
              <div>
                <img src={ShibuNavbarLogo} alt=''></img>
              </div>

              <div className='d-flex align-items-center relative'>
                <div className='d-flex'>
                  <div className='relative'>
                    <button className='mx-1'>
                    <i class="fa-solid fa-cart-shopping f-lg clr-33363F"></i>
                    </button>
                    <div className='absolute position-middle-r'><i class="fa-solid fa-circle lightbrick f-8"></i></div>
                  </div>

                  <div className='relative'>
                    <button className='mx-1'>
                    <i class="fa-regular fa-heart f-lg clr-33363F"></i>
                    </button>
                    <div className='absolute position-middle-r'><i class="fa-solid fa-circle lightbrick f-8"></i></div>
                  </div>
                </div>
                <button className='f-xs fw-500' onClick={this.toggleSettings}>
                  <i class="fa-regular fa-user f-lg me-2 clr-33363F"></i>Kelly<i class="fa-solid fa-chevron-down f-xs ms-1"></i>
                </button>
                <div id='popupboxsettingmini' onClick={this.toggleSettings}>
                  <div className='d-flex flex-column bg-white radius-11 popupboxsettings box-shadow absolute pt-4'>
                    <div className='px-3 mb-3 d-flex'>
                      <div className='me-2'>
                        <img src={userimg} alt='' className='popupdataimg'></img>
                      </div>
                      <div>
                      <div className='fw-500 f-xs'>Charlette Eleanor</div>
                      <div className='f-xxs clr-868790'>elanor@untitledui.com</div>
                      </div>
                    </div>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' to='/Layout5/Userprofsetting'><i class="fa-regular fa-user me-2"></i>Edit Profile</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' to='/Layout5/Cartaddress'><i class="fa-solid fa-location-dot me-2"></i>Address</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' data-toggle="modal" data-target="#exampleModal"><i class="fa-regular fa-bell me-2"></i>Notification</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' to='/Layout5/Userorderpage'><i class="fa-solid fa-bag-shopping me-2"></i>Order</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' data-toggle="modal" data-target="#exampleModalPayment"><i class="fa-regular fa-credit-card me-2"></i>Payment</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2'  data-toggle="modal" data-target="#exampleModalSecurity"><i class="fa-solid fa-shield-halved me-2"></i>Security</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' data-toggle="modal" data-target="#exampleModalPrivacy"><i class="fa-solid fa-lock me-2"></i>Privacy Policy</Link>
                    <Link className='onhoverlightbrick clr-868790 fw-500 px-3 f-xs mb-2' to='/Layout5/Faq'><i class="fa-regular fa-circle-question me-2"></i>Help Center</Link>
                    <Link className='onhoverlightbrick logout bg-lightbrick white-clr fw-500 px-3 f-xs mb-2 text-center py-2 mb-0 mt-3 underline-none'><i class="fa-solid fa-arrow-right-from-bracket me-2"></i>Logout</Link>
                  </div>
                </div>

                
              </div>
            </div>
            <div className='top-navbar bg-transparent w-100'>
              <div className='d-flex align-items-center'>
                {/* <div className='black-clr me-4'><b>Overview</b></div> */}
                <div className='relative'>
                    <input className='nav-search-inp' placeholder='Search'></input>
                    <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
                </div>
              </div>
              <div className='d-flex align-items-center relative'>
              <div className='fw-600 f-xs d-flex align-items-center' onClick={this.toggleCategory}><span class="material-symbols-outlined f-30 me-2 clr-33363F">apps</span>Categories</div>
              <div className='bg-white radius-11 box-shadow absolute categoriespopuo p-3' id='categoriespopupmini' onClick={this.toggleCategory}>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems' to="/Layout5/Catfish"><i class="fa-solid fa-fish me-2 f-lg"></i>Fish</Link></div>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems' to="/Layout5/Catmeat"><i class="fa-solid fa-drumstick-bite me-2 f-lg"></i>Meat</Link></div>
                   <div className='mb-2'><Link className='clr-868790 fw-500 brick-clr catitems'><i class="fa-solid fa-shrimp me-2 f-lg"></i>Seafood</Link></div>
                   <div><Link className='clr-868790 fw-500 brick-clr catitems'  to="/Layout5/Catoffer"><i class="fa-solid fa-tags me-2 f-lg"></i>Best Offers</Link></div>
                </div>
              </div>
            </div>
        </div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-sm" role="document">
    <div className="modal-content">
     
      <div className="modal-body">
      <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                  <div className='fw-600 f-xs'>Notification</div>
                  <div><i class="fa-solid fa-xmark" data-dismiss="modal" ></i></div>
                </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn1" className='toggle-btn'></input>
                <label for="toggle-btn1" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Email Notification </div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn2" className='toggle-btn'></input>
                <label for="toggle-btn2" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>SMS Notification </div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn3" className='toggle-btn'></input>
                <label for="toggle-btn3" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Special Offers</div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn4" className='toggle-btn'></input>
                <label for="toggle-btn4" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Promo & Discount</div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn5" className='toggle-btn'></input>
                <label for="toggle-btn5" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Payement</div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn6" className='toggle-btn'></input>
                <label for="toggle-btn6" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Cashback</div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn7" className='toggle-btn'></input>
                <label for="toggle-btn7" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Order Tracking </div>
        </div>
        <div className='d-flex mt-3'>
            <div className='me-2'>
                <input type="checkbox" id="toggle-btn8" className='toggle-btn'></input>
                <label for="toggle-btn8" className='toggle-btn-label'></label>
            </div>
            <div className='fw-500 f-xs'>Feedback and reviews</div>
        </div>
        <button className='f-xs lightbrick-btn w-100 mt-4'>Save</button>
        
      <div>

        </div>
      </div>
    </div>
  </div>
        </div>


        <div className="modal fade" id="exampleModalSecurity" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
            
              <div className="modal-body">
              <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                  <div className='fw-600 f-xs'>Security</div>
                  <div><i class="fa-solid fa-xmark" data-dismiss="modal" ></i></div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn1" className='toggle-btn'></input>
                        <label for="toggle-btn1" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Remember me</div>
                </div>
                <div className='d-flex mt-3'>
                    <div className='me-2'>
                        <input type="checkbox" id="toggle-btn2" className='toggle-btn'></input>
                        <label for="toggle-btn2" className='toggle-btn-label'></label>
                    </div>
                    <div className='fw-500 f-xs'>Google Authenticator </div>
                </div>
                
                <button className='f-xs lightbrick-btn w-100 mt-4'>Save</button>
                
              <div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="exampleModalPayment" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
            
              <div className="modal-body">
              <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                  <div className='fw-600 f-xs'>Payment</div>
                  <div><i class="fa-solid fa-xmark" data-dismiss="modal" ></i></div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='me-2 d-flex align-items-center'>
                        <img src={paypal} alt=''></img>
                        <div className='fw-500 f-xs ms-2'>Paypal</div>
                    </div>
                    <div className='fw-600 f-xs lightbrick'>Connected</div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='me-2 d-flex align-items-center'>
                        <img src={google} alt=''></img>
                        <div className='fw-500 f-xs ms-2'>Google Pay</div>
                    </div>
                    <div className='fw-600 f-xs lightbrick'>Connected</div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='me-2 d-flex align-items-center'>
                        <img src={apple} alt=''></img>
                        <div className='fw-500 f-xs ms-2'>Apple Pay</div>
                    </div>
                    <div className='fw-600 f-xs lightbrick'>Connected</div>
                </div>
                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <div className='me-2 d-flex align-items-center'>
                        <img src={upi} alt=''></img>
                        <div className='fw-500 f-xs ms-2'><span>*********</span>456</div>
                    </div>
                    <div className='fw-600 f-xs lightbrick'>Connected</div>
                </div>
               
                
                <button className='f-xs lightbrick-btn w-100 mt-4' data-toggle="modal" data-target="#exampleModalAddNewcard">Add New Card</button>
                
              <div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="exampleModalAddNewcard" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
            
              <div className="modal-body bg-F9F9F9">
                <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                  <div className='fw-600 f-xs'>Add New Card</div>
                  <div><i class="fa-solid fa-xmark" data-dismiss="modal" ></i></div>
                </div>
                <div className='mb-2'>
                  <div className='fw-500 f-xs'>Card Name</div>
                  <input className='inp-FFF w-100 py-1'></input>
                </div>
                <div className='mb-2'>
                  <div className='fw-500 f-xs'>Card Number</div>
                  <input className='inp-FFF w-100 py-1'></input>
                </div>
                <div className='mb-2 d-flex w-100'>
                  <div className='w-500 me-2'>
                    <div className='fw-500 f-xs'>Expiry Date</div>
                    <input type='month' className='inp-FFF w-100 py-1'></input>
                  </div>
                  <div className='w-500'>
                    <div className='fw-500 f-xs'>CVV</div>
                    <input type='number' className='inp-FFF w-100 py-1'></input>
                  </div>
                  
                </div>
                <button className='f-xs lightbrick-btn w-100 mt-4'>Add New Card</button>
                
              <div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade exampleModalPrivacy" id="exampleModalPrivacy" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
            
              <div className="modal-body f-xs bg-F9F9F9">
                <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                  <div className='fw-600 f-xs'>Privacy Policy</div>
                  <div><i class="fa-solid fa-xmark" data-dismiss="modal" ></i></div>
                </div>
                <div className='p-3 box-shadow bg-whitealtbrick radius-11 mb-3'>
                  <div className='fw-500 mb-2'>Data Collection</div>
                  <div className=''>
                      We collect information from users in several ways, including when users place an order, sign up for our newsletter, or participate in surveys. 
                      The types of information we collect include: <br></br>

                      Contact information, such as name, address, email address, and phone number
                      Payment information, such as credit card numbers or other payment details
                      Personal preferences and demographic information, such as age and gender
                      Information about orders placed on the Website, including items purchased and delivery information
                  </div>
                </div>
                <div className='p-3 box-shadow bg-whitealtbrick radius-11 mb-3'>
                  <div className='fw-500 mb-2'>Data Use</div>
                  <div>
                      We use the information we collect for several purposes, including:
                      To process orders and deliver products
                      To send periodic emails about our products, services, and special offers
                      To respond to customer inquiries and support requests
                      To improve our Website and services
                  </div>
                </div>
                <div className='p-3 box-shadow bg-whitealtbrick radius-11 mb-3'>
                  <div className='fw-500 mb-2'>Data Disclosure</div>
                  <div>
                    We do not sell or rent your personal information to third parties. However, we may disclose your information to third-party service providers who
                    assist us with the Website or in connection with the services we provide. These service providers are contractually obligated to maintain the 
                    confidentiality and security of your information.
                  </div>
                </div>
                <div className='p-3 box-shadow bg-whitealtbrick radius-11 mb-3'>
                  <div className='fw-500 mb-2'>Security</div>
                  <div>
                    We take the security of your personal information seriously and have implemented measures to protect it from unauthorized access, use,
                    disclosure, and destruction. However, no security system is impenetrable, and we cannot guarantee the security of our systems or the
                    information we collect.
                  </div>
                </div>
                
                
              <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserToolbar


