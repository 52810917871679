import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
import Toolbar from '../UserToolbar/UserToolbar';
import Footer from '../Footer/Footer'
import './Layout5.css'

export class Layout5 extends Component {
  render() {
    return (
        <React.Fragment>
        <div className='w-100 ff-mon pad-100 py-4'>
            <Toolbar></Toolbar>
            <Outlet />
            <Footer></Footer>
        </div>

      </React.Fragment>
    )
  }
}

export default Layout5