import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Registration.css';


export class Registration extends Component {
    render() {
        return (
                <div className='bg-white box-shadow p-5 w-30 ff-mon m-auto radius-11 zindex1'>
                    <div className='f-xl black-clr mb-2 ff-dmsans'><b>Sign In</b></div>

                    <form>

                        <div className='mb-2'>
                            <div className='lightgrey-clr f-xxs text-start'>USERNAME</div>
                            <div><input className='inp-F5F5F5 radius-7 p-2 clr-202020 f-xs w-100' value='olivertwist01'></input></div>
                        </div>
                        <div className='mb-2'>
                            <div className='lightgrey-clr f-xxs text-start'>EMAIL-ID</div>
                            <div><input className='inp-F5F5F5 radius-7 clr-202020 p-2 f-xs w-100' value='olivertwist@gmail.com'></input></div>
                        </div>
                        <div className='mb-3'>
                            <div className='lightgrey-clr f-xxs text-start'>PASSWORD</div>
                            <div className='relative'>
                                <input className='inp-F5F5F5 radius-7 clr-202020 p-2 f-xs w-100' type='password'></input>
                                <div className='absolute position-middle-r'><i className="fa-regular fa-eye brick-clr"></i></div>

                            </div>
                        </div>
                        <div className='mb-3 d-flex align-items-center'>
                            <input type="checkbox" id="termsandconditions" name="termsandconditions" className='TaC-checkbox-size' value="0"></input>
                            <label for="termsandconditions" className='f-xs black-clr ms-1'>I accept the terms and conditions</label>
                        </div>
                        <div>
                            <button className='brick-btn f-xs w-100'>SIGN UP NOW</button>
                        </div>

                    </form>

                    <div className='d-flex w-100 justify-content-center mt-4 mb-2'>
                        <button className='sm-btns'><i className="fa-brands fa-google f-lg clr-202020"></i> </button>    
                        <button className='mx-3 sm-btns'><i className="fa-brands fa-twitter f-lg clr-202020"></i></button>   
                        <button className='sm-btns'><i className="fa-brands fa-facebook-f f-lg clr-202020"></i></button>            
                    </div>
                    <div><span className='lightgrey-clr f-xs'>Already have an account ?</span><span className='ms-2'><Link to="/login" className='brick-clr f-xs loginlink-anc'>Login</Link></span></div>


                </div>
            
        )
    }
}

export default Registration

