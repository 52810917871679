import React, { Component } from 'react'
import './Catfish.css'
import MultiRangeSlider from "multi-range-slider-react";
import img3 from '../../images/Topselling/topsellingfish2.jpg';
import { Link } from 'react-router-dom'

export class Catfish extends Component {
  myFunction() {
    document.getElementsByClassName("div1")[0].classList.toggle("open");}
  
    state = { values: [0,1000] };
    constructor(props) {
      super(props);
      this.state = {
        minValue: 0,
        maxValue:1000
      };
      this.minRef = React.createRef()
      this.maxRef = React.createRef()
    }
    handleInput(e) {
      this.minRef.current.value = '$ '+e.minValue;
      this.maxRef.current.value = '$ '+e.maxValue;
    };
    render() {
        
        return (
         <div className='catoffer catfish'>
            <div className='w-100 banner-img mb-5'>
                <div className='f-40 lh-33 mb-1 mb-lg-3'>
                <b className='black-clr'>Discover the best in </b><br></br>
                <span className='brick-clr ff-carousaly'><b>fresh meats and</b></span><br></br>
                <span className='brick-clr ff-carousaly' me-2><b>seafood</b></span><span className='black-clr ms-3'><b>at our shop</b></span>
                </div>
    
                <div className='f-sm clr-868790 fw-500 pe-3 pe-lg-0'>
                Lorem ipsum dolor sit amet. Vel illum maiores hic<br></br> dolore qui reprehenderit odit aut dolores illum.
                </div>
    
                <button className='lightbrick-btn mt-3'>Show Now <i className="fa-solid fa-chevron-right"></i></button>
            </div>
            <div className='d-flex align-items-end justify-content-end d-block d-lg-none mb-3'>
              <button className='lightbrick-btn-outline px-3 hamburger-btn' onClick={this.myFunction}><i class="fa-solid fa-filter me-2"></i>Filter</button>
            </div>
            <div className='w-100 d-flex'>
              <div className='div1 bg-white box-shadow radius-11 py-3 me-3'>
                <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Price <i class="fa-solid fa-sterling-sign brick-clr f-xs"></i></div>
                <div className='p-3'>
                    <div className='w-100 relative d-flex'>
                      <input ref={ this.minRef} className='min-max' readOnly/> 
                      <input ref={this.maxRef} className='min-max'readOnly/>
                    </div>
    
                    <div>
                      <MultiRangeSlider
                      min={0}
                      max={1000}
                      ruler={false}
                      labels={false}
                      label={false}
                      onInput={(e) => {
                        this.handleInput(e);
                      }}/>
                    </div>
                </div>
                <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Best offers</div>
                <div className='p-3'>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Brisket (chopped)</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>beef heart</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>indian mackeral</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>silver promfret</label>
                  </div>
                </div>
                <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Categories</div>
                <div className='p-3'>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Meat</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Fish</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Sea shell</label>
                  </div>
                </div>
                <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Availability</div>
                <div className='p-3'>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>In stock</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Out of stock</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>Preorder</label>
                  </div>
                </div>
                <div className='bg-F9F9F9 py-2 px-3 f-xs fw-600'>Discount</div>
                <div className='p-3'>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>10% to 20%</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>20% to 30%</label>
                  </div>
                  <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 clr-898989 f-13 fw-500'>30% to 50%</label>
                  </div>
                </div>
                <div className='p-3 w-100 d-flex'>
                  <button className='white-btn f-13 fw-500 w-50 me-2'>Cancel</button>
                  <button className='lightbrick-btn f-13 fw-500 w-50'>Apply</button>
                </div>
              </div>
              <div className='w-75-resp-mob radius-11 p-0 p-lg-3'>
                {/* <div className='bg-white py-2 px-3 f-sm fw-600 radius-11 w-100 d-flex justify-content-center mb-2'>Best offers</div> */}
                <div className="grid-container bestofferlist mb-4">
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                 
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className='bg-white box-shadow px-2 px-lg-3 py-3 py-lg-4 radius-7 text-center d-flex flex-column justify-content-center onhovergradbrick'>
                        <div className='mb-3 relative'>
                        <img src={img3} alt='' className='topsellingfish-items'></img>
                        <div className='absolute heartposition'>
                          <i className="fa-regular fa-heart f-md"></i>
                        </div>
                        </div>
                        <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                        <div className='f-xxs clr-565B67'>A quality British fish</div>
                        <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                        <div className='d-flex mt-2 px-0 px-lg-3 justify-content-center w-100'>
                              <Link className='lightbrick-btn f-13 h-27 px-1 px-lg-2 underline-none d-flex align-items-center justify-content-center' to="/Layout5/addcart">Add to bag</Link>
                              <div className='f-xxs kilobg'>2 Kg</div>
                          </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
           
         </div>
        )
      }
}

export default Catfish