import React from 'react'
import ShibuNavbarLogo from '../../images/ShibuNavbarLogo.svg';
import './Navbar.css';
import { Link } from 'react-router-dom'


export default function Navbar() {
    return (

        <div className='w-100 h-100 navbarmain'>
            <div className='p-4 postionsticky'>
                <img src={ShibuNavbarLogo} width="100" height="50" alt="" />
            </div>
            <div className='white-clr mt-4'>
                <div className='clr-E7E7E7 f-xs px-4'>GENERAL</div>
                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Overview" className='white-clr underline-none px-4'><i className="fa-solid fa-laptop me-3 f-sm"></i><span className='f-xs'>Overview</span></Link>
                </div>

                <div className='link-item d-flex align-items-center hamburger-btn'>
                    <Link to="/Layout2/Analytics" className='white-clr underline-none px-4'><i className="fa-solid fa-chart-line me-3 f-sm"></i><span className='f-xs'>Analytics</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Ecommerce" className='white-clr underline-none px-4'><i className="fa-solid fa-bag-shopping me-3 f-sm"></i><span className='f-xs'>Ecommerce</span></Link>
                </div>

            </div>
            <div className='white-clr mt-4'>
                <div className='clr-E7E7E7 f-xs px-4'>CONCEPTS</div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Catalog" className='white-clr underline-none px-4'><i className="fa-solid fa-database me-3 f-sm"></i><span className='f-xs'>Catalog</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Customer" className='white-clr underline-none px-4'><i className="fa-regular fa-user me-3 f-sm"></i><span className='f-xs'>Customers</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Coupon" className='white-clr underline-none px-4'><i className="fa-solid fa-receipt me-3 f-sm"></i><span className='f-xs'>Coupon</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Sales" className='white-clr underline-none px-4'><i className="fa-solid fa-cart-shopping me-3 f-sm"></i><span className='f-xs'>Sales</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Purchase" className='white-clr underline-none px-4'><i class="fa-solid fa-bag-shopping me-3 f-sm"></i><span className='f-xs'>Purchase</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Invoice" className='white-clr underline-none px-4'><i className="fa-regular fa-file-lines me-3 f-sm"></i><span className='f-xs'>Invoice</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Maintanance" className='white-clr underline-none px-4'><i className="fa-regular fa-envelope me-3 f-sm"></i><span className='f-xs'>Inbox</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Maintanance" className='white-clr underline-none px-4'><i className="fa-regular fa-calendar-days me-3 f-sm"></i><span className='f-xs'>Calender</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Maintanance" className='white-clr underline-none px-4'><i className="fa-regular fa-comments me-3 f-sm"></i><span className='f-xs'>Chat</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="/Layout2/Maintanance" className='white-clr underline-none px-4'><i className="fa-solid fa-gear me-3 f-sm"></i><span className='f-xs'>File Manager</span></Link>
                </div>

                <div className='link-item d-flex align-items-center'>
                    <Link to="#" className='white-clr underline-none px-4'><i className="fa-regular fa-circle-xmark me-3 f-sm"></i><span className='f-xs'>Not Found</span></Link>
                </div>

            </div>
        </div>

    )
}
