import React, { Component } from 'react'
import errorimg from '../../images/Error/error.svg'
import './Error.css'
export class Error extends Component {

  render() {
    
    return (
      <div>
        <div className='box-shadow p-4 d-flex flex-column justify-content-center align-items-center radius-11'>
            <div className='mb-3 fw-500'>What you are looking for isn't here</div>
            <div>
                <div><img src={ errorimg } alt='' className='errorimgwid'></img></div>
            </div>
            <div className='fw-500'>for your safety, please step back</div>

            <button className='lightbrick-btn f-xs px-4 mt-3'>Back to home</button>
        </div>
      </div>
    )
  }
}

export default Error
