import React, { Component } from 'react'
import customer from '../../images/Invoice/Customer.svg';
import from from '../../images/Invoice/From.svg';
import './Viewinvoice.css'

export class Viewinvoice extends Component {
  render() {
    return (
      <div className='px-4'>
        <div className='d-flex w-100 justify-content-between mb-5'>
          <div className='d-flex align-items-center'>
            <div className='fw-600 black-clr me-2'>Invoice</div>
            <div className='f-xs clr-898989 fw-500'>#INV-00012456</div>
          </div>
          <div className='d-flex align-items-center'>
            <button className='plain-lightbrick-btn f-xs me-3 px-2'><i className="fa-solid fa-check-double me-2"></i>Invoice Sent</button>
            <button className='brick-btn f-xs px-3'><i className="fa-solid fa-download me-2"></i>Dowload</button>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-between'>
          <div className='w-50'>
            <div className='clr-898989 f-12 mb-3'>FROM</div>
            <div className='w-100 d-flex align-items-center'>
              <div>
                <img src={ from } className='view-invoice-proimg' alt=''></img>
              </div>
              <div className='ms-3 black-clr'>
                <div className='f-xs fw-600 mb-2'>SIBU FISH N’ MEAT</div>
                <div className='f-12 fw-500'>18  Guild Street London, EC2V 5PX</div>
                <div className='f-12 fw-600'>United Kingdom</div>
                <div className='f-12 fw-500'><span className='me-2'>sibu@gmail.com</span><span>Ph: 9876543210</span></div>
              </div>
            </div>
          </div>
          <div className='w-50'>
            <div className='clr-898989 f-12 mb-3'>CUSTOMER</div>
            <div className='w-100 d-flex align-items-center'>
              <div>
                <img src={ customer } className='view-invoice-proimg' alt=''></img>
              </div>
              <div className='ms-3 black-clr'>
                <div className='f-xs fw-600 mb-2'>Alessia Zara Thomas</div>
                <div className='f-12 fw-500'>18  Guild Street London, EC2V 5PX</div>
                <div className='f-12 fw-600'>United Kingdom</div>
                <div className='f-12 fw-500'><span className='me-2'>customer@gmail.com</span><span>Ph: 9876543210</span></div>
              </div>
            </div>

          </div>
        </div>
        <div className='gradientline'></div>
        <div className='d-flex w-100 justify-content-between mb-4'>
          <div className='w-50'>
            <div className='clr-898989 f-12 mb-2'>PROJECT NAME</div>
            <div className='fw-600 black-clr f-sm'>Lorem ipsum dolor sit amet</div>
          </div>
          <div className='w-50'>
            <div className='clr-898989 f-12 mb-2'>DUE DATE</div>
            <div className='brick-clr f-xs fw-500'>Saturday October 24th, 2020</div>
          </div>
        </div>
        <div className="">
                <div className="row">
                    <div className="col-12">
                    <table className="table table-bordered" aria-label="TABLE">
                        <thead>
                        <tr role="row" className='f-12 clr-565B67'>
                            <th role="columnheader" scope="col" tabIndex="0">ITEM DESCRIPTION</th>
                            <th role="columnheader" scope="col" tabIndex="0">DURATION</th>
                            <th role="columnheader" scope="col" tabIndex="0">RATE</th>
                            <th role="columnheader" scope="col" tabIndex="0">AMOUNT</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr role="row" className='black-clr f-12 fw-500'>
                            <td>Beef Heart</td>
                            <td>12h</td>
                            <td>45.00</td>
                            <td><i className="fa-solid fa-sterling-sign"></i>900.00</td>
                        </tr>
                        <tr role="row" className='black-clr f-12 fw-500'>
                            <td>Salmon</td>
                            <td>12h</td>
                            <td>45.00</td>
                            <td><i className="fa-solid fa-sterling-sign"></i>900.00</td>
                        </tr>
                        <tr role="row" className='black-clr f-12 fw-500'>
                            <td className='clr-898989 fw-600'>SUBTOTAL</td>
                            <td></td>
                            <td></td>
                            <td><i className="fa-solid fa-sterling-sign"></i>17,990.00</td>
                        </tr>
                        <tr role="row" className='black-clr f-12 fw-500'>
                            <td className='clr-898989 fw-600'>TAX</td>
                            <td></td>
                            <td></td>
                            <td>2<i className="fa-solid fa-percent ms-1"></i></td>
                        </tr>
                        <tr role="row" className='black-clr f-12 fw-500'>
                            <td className='clr-898989 fw-600'>TOTAL</td>
                            <td></td>
                            <td></td>
                            <td className='fw-600 f-sm'><i className="fa-solid fa-sterling-sign"></i>17,990.00</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
        </div>
      </div>
    )
  }
}

export default Viewinvoice