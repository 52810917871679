import React, { Component } from 'react'
import "react-step-progress-bar/styles.css";
import './Paymentoptions.css'
import { ProgressBar, Step } from "react-step-progress-bar";
import fishimg from '../../images/fish.jpg'

export class Paymentoptions extends Component {
    togglecards() {
        var x = document.getElementById("wp-comment-cookies-consentdb").checked = true;
        alert('op');
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      
    }
  render() {
    return (
        <div className='checkout'>
        <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
          <ProgressBar percent={75}>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
          </ProgressBar>
        </div>
        
        <div className='d-flex flex-column flex-lg-row w-100 mt-4'>
          <div className='w-60-resp-mob radius-11 box-shadow p-3 me-0 me-lg-3 bg-white mb-3 mb-lg-0'>
            
            <div className='fw-600 brick-clr f-xs mb-2'>Payment Option</div>
            <div className='fw-500 f-xs mb-2'>Choose an option</div>
            <div className='d-flex flex-column flex-lg-row'>
                <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Pay Pal</label>
                </div>
                <div className="comment-form-cookies-consent d-flex mb-2 mx-3">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Apple Pay</label>
                </div>
                <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Google Pay</label>
                </div>
                
            </div>
            <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consentdb" name="wp-comment-cookies-consent"  onclick={this.togglecards} type="radio" value="yes"></input>
                <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Credit/Debit/ATM Card</label>
            </div>
           
            <div id='popupdebitcredit'>
                <div className='d-flex mb-2'>
                <input className='inp-F0F0F0 p-2 f-xxs f-500 me-3' placeholder='Enter card Number'></input>
                <div className='d-flex align-items-center'>
                <div className='f-13 fw-500'>Validity</div>
                <input type='month' className='inp-F0F0F0 p-2 f-xxs f-500 ms-2' placeholder='MM'></input>
                </div>
                </div>
                <input type='number' className='inp-F0F0F0 p-2 f-xxs f-500 me-3' placeholder='CVV'></input>

            </div>


            <div className='f-xxs clr-898989 my-3 fw-600'>Add and secure you card as per BoE guideliness</div>
            <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Net Bnaking</label>
            </div>
            <div className="comment-form-cookies-consent d-flex mb-2">
                <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Cash on Delivery</label>
            </div>
            <div className='f-xxs clr-898989 my-3 fw-600'>Order confirmation email will be sent to <span className='lightbrick'>kellyclarkson@gmail.com</span></div>

          </div>
          <div className='w-40-resp-mob radius-11 box-shadow p-3 bg-white'>
            <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
                <strong>
                    Ordering with ease,<br></br>
                    delivering with <br></br>
                    <span className='brick-clr'>pleasure</span>
                </strong>
            </div>
            <div className='py-3 px-5'>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Price Details</div>
                  <div className='w-50'>7 Items</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total</div>
                  <div className='w-50'>45(15/kg)</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Discount</div>
                  <div className='w-50'>No Discount</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Convinience Fee</div>
                  <div className='w-50'>99 Free</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total Amount</div>
                  <div className='w-50 brick-clr'><strong>45(15/kg)</strong></div>
                </div>
              </div>
            </div>
            <div className='py-1 px-5'>
            <button className='lightbrick-btn f-xs w-100 mx-auto'>Place your order</button>
            </div>

            <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline'>Contact Us</span></div>
           
          </div>
        </div>
      </div>
    )
  }
}

export default Paymentoptions
