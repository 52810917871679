import React, { Component } from 'react'
import './Ecommerce.css'
import beef from '../../images/meat.jpg'
import Chart from "react-apexcharts";
import { Link } from 'react-router-dom'

export class Ecommerce extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
          series: [{
            name: 'Customer',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5]
          }],
          options: {
            chart: {
              height: 350,
              type: 'line',
            },
            forecastDataPoints: {
              count: 7
            },
            stroke: {
              width: 5,
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000'],
              tickAmount: 10,
              labels: {
                formatter: function(value, timestamp, opts) {
                  return opts.dateFormatter(new Date(timestamp), 'dd MMM')
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                gradientToColors: [ 'red'],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
              },
            },
            yaxis: {
              min: 0,
              max: 60
            }
          },
        
        
        };
      }
    
  render() {
    
    return (
      <div className='ecommerce'>
        <div className='w-100 d-flex flex-column flex-lg-row'>
            <div className='w-60-resp-tab me-3'>
                <div className='mb-4 radius-7 box-shadow p-4 mb-4'>
                    <div className='fw-600 mb-4'>Today's Status</div>
                    <div className='d-flex'>
                        <div className='box-decoration zoomoutonhover'>
                            <div className='mb-3 icon'><i class="fa-solid fa-chart-line"></i></div>
                            <div className='fw-600 f-lg'>Profit</div>
                            <div className='fw-600 f-lg'>$32.1k</div>
                        </div>
                        <div className='box-decoration white mx-3 zoomoutonhover'>
                            <div className='mb-3 icon black'><i class="fa-solid fa-chart-column"></i></div>
                            <div className='fw-600 f-lg'>Sales</div>
                            <div className='fw-600 f-lg'>$152k</div>
                        </div>
                        <div className='box-decoration zoomoutonhover'>
                            <div className='mb-3 icon'><i class="fa-solid fa-ticket"></i></div>
                            <div className='fw-600 f-lg'>Cost</div>
                            <div className='fw-600 f-lg'>$99.7k</div>
                        </div>
                    </div>
                </div>
                <div className='mb-4 radius-7 box-shadow p-4 mb-4'>
                <div className='fw-600 mb-4'>New Customer</div>

                    <div id="chart">
                        <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
                    </div>
                
                </div>

                <div className='mb-4 radius-7 box-shadow p-4'>
                    <div className='brick-clr fw-600 f-sm'>Top regions by revenue</div>
                    <div className='f-xxs fw-600'>Where you generated most of the revenue</div>
                    <div className='region-table mt-3'>
                        <table class="rwd-table mb-2">
                            <tbody>
                            <tr>
                                <th>CITIES</th>
                                <th>USERS</th>
                                <th>TRANSACTION</th>
                                <th>REVENUE</th>
                                <th>CONV.RATE</th>
                            </tr>
                            <tr>
                                <td data-th="Supplier Numberr">
                                Seaton
                                </td>
                                <td data-th="Supplier Name">
                                92
                                </td>
                                <td data-th="Invoice Number">
                                618
                                </td>
                                <td data-th="Invoice Date">
                                £7560
                                </td>
                                <td data-th="Due Date">
                                14.01%
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Code">
                                Seaton
                                </td>
                                <td data-th="Supplier Name">
                                92
                                </td>
                                <td data-th="Invoice Number">
                                618
                                </td>
                                <td data-th="Invoice Date">
                                £7560
                                </td>
                                <td data-th="Due Date">
                                14.01%
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Code">
                                Seaton
                                </td>
                                <td data-th="Supplier Name">
                                92
                                </td>
                                <td data-th="Invoice Number">
                                618
                                </td>
                                <td data-th="Invoice Date">
                                £7560
                                </td>
                                <td data-th="Due Date">
                                14.01%
                                </td>
                            </tr>
                            <tr>
                                <td data-th="Supplier Code">
                                Seaton
                                </td>
                                <td data-th="Supplier Name">
                                92
                                </td>
                                <td data-th="Invoice Number">
                                618
                                </td>
                                <td data-th="Invoice Date">
                                £7560
                                </td>
                                <td data-th="Due Date">
                                14.01%
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='w-40-resp-tab box-shadow p-3 radius-7'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='fw-600'>Top Selling Products</div>
                    <div>
                        <Link to="/Layout4/topsellingfish" className='white-btn f-xs p-2 fw-500 underline-none'>See all<i class="fa-solid fa-arrow-right ms-1"></i></Link>
                    </div>
                </div>
                <div className='d-none d-md-block'>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom'>
                        <img src={beef} alt='' className='topsellingimg'></img>
                        <div className='fw-500 f-xs'>Wild Pork Diced<br></br>(with bone & skin)</div>
                        <div className='f-xs clr-565B67'>13,153<br></br>in sales</div>
                        <div className='brick-clr fw-600 number center f-xs box-shadow'>#1</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default Ecommerce
