import React, { Component } from 'react'
import './Addproduct.css'
import { Link } from 'react-router-dom'

export class Addproduct extends Component {
  render() {
    return (
      <div className='px-3 px-lg-5'>
        <div className='d-flex flex-column flex-md-row w-100 mb-3'>
            <div className='bg-f8f8f8 p-4 w-60-resp-mob me-3 box-shadow radius-11 mb-3 mb-md-0'>
                <div className='fw-500 clr-202020 f-14 mb-4'>Basic information</div>
                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Name</div>
                    <div><input className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Category</div>
                    <div><input className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
                <div className='mb-3'>
                    <div className='f-13 fw-500 mb-1'>Product Description</div>
                    <div>
                        <textarea className='inp-F0F0F0 w-100 px-2 py-3 fw-500' rows='7'>
                        Lorem ipsum dolor sit amet. Eum esse voluptatum quo velit nihil et enim excepturi 
                        eos obcaecati temporibus aut voluptas similique? Qui aspernatur voluptates qui
                        dicta incidunt aut distinctio dolorum ut dolor excepturi. Et dolor explicabo et
                        </textarea>
                    </div>



                </div>
            </div>
            <div className='bg-f8f8f8 p-4 w-40-resp-mob box-shadow radius-11'>
                <div className='fw-500 clr-202020 f-14 mb-4'>Visibility</div>

                <section className="section-form">
                    <div className="form">
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="first" name="animals"></input>
                        <label for="first" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Published
                        </label>
                        </div>
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="second" name="animals"></input>
                        <label for="second" className="form__radio-label mb-1">
                            <span className="form__radio-btn"></span>
                            Sheduled
                        </label>
                        </div>
                        
                        <div className="form__radio-group">
                        <input type="radio" className="form__radio-input" id="third" name="animals" checked></input>
                        <label for="third" className="form__radio-label">
                            <span className="form__radio-btn"></span>
                            Hidden
                        </label>
                        </div>
                        
                        
                    </div>
                </section>

                <div className='mb-3 mt-4'>
                    <div className='f-13 fw-500 mb-1'>Delivery Date </div>
                    <div><input type='date' placeholder='' className='inp-F0F0F0 w-100 p-2 fw-500'></input></div>
                </div>
                <div className='my-3'>
                    <div className='f-13 fw-500 mb-1'>Categories</div>
                    <div>
                    <select name="cars" id="cars"  className='inp-F0F0F0 w-100 p-2 fw-500'>
                        <option value="">Choose..</option>
                        <option value="volvo">Meat</option>
                        <option value="saab">Fish</option>
                        <option value="mercedes">Seashell</option>
                        </select>
                    </div>
                </div>
                <div className='my-3'>
                    <div className='f-13 fw-500 mb-1'>Tags</div>
                    <div>
                    <select name="cars" id="cars"  className='inp-F0F0F0 w-100 p-2 fw-500'>
                        <option value="">Choose..</option>
                        <option value="volvo">Meat</option>
                        <option value="saab">Fish</option>
                        <option value="mercedes">Seashell</option>
                        </select>
                    </div>
                </div>

              

            </div>
        </div>

        <div className='d-flex flex-column flex-md-row w-100 mb-3'>
            <div className='bg-f8f8f8 p-4 w-60-resp-mob me-3 box-shadow radius-11 mb-3 mb-md-0'>
                <div className='fw-500 clr-202020 f-14 mb-4'>Pricing</div>
                <div className='d-flex w-100'>
                    <div className='w-50 me-2'>
                        <div className='f-13 fw-500 mb-1'>Price</div>
                        <div>
                            <input type="number" className='inp-F0F0F0 p-2 w-100'></input>
                        </div>
                    </div>
                    <div className='w-50'>
                        <div className='f-13 fw-500 mb-1'>Old Price</div>
                        <div>
                            <input type="number" className='inp-F0F0F0 p-2 w-100'></input>
                        </div>
                    </div>
                </div>
                <div className='mt-1'>
                    <Link className='lightbrick f-xxs fw-500'>Schedule discount</Link>
                    

                </div>

            </div>
            <div className='bg-f8f8f8 p-4 w-40-resp-mob box-shadow radius-11'>
            <div className='fw-500 clr-202020 f-14 mb-4'>Stock Quantity</div>
                <div className='w-100'>
                    <div className='mb-1 transparent fw-500 clr-202020 f-14 mb-1'>Stock Quantity</div>
                    <div>
                        <input type="number" className='inp-F0F0F0 p-2 w-100'></input>
                    </div>
                </div>
            </div>
        </div>

        <div className='d-flex w-100 flex-column flex-md-row'>
            <div className='bg-f8f8f8 p-4 w-60-resp-mob me-3 box-shadow radius-11 mb-3 mb-md-0'>
                <div className='fw-500 clr-202020 f-14 mb-4'>Images</div>
                <div className="wrapper">
                    <div className="box">
                        <div className="js--image-preview"></div>
                        <div className="upload-options">
                        <label>
                            <input type="file" className="image-upload" value='' accept="image/*" />
                        </label>
                        </div>
                    </div>

                    <div className="box">
                        <div className="js--image-preview"></div>
                        <div className="upload-options">
                        <label>
                            <input type="file" className="image-upload" value='' accept="image/*" />
                        </label>
                        </div>
                    </div>

                    <div className="box">
                        <div className="js--image-preview"></div>
                        <div className="upload-options">
                        <label>
                            <input type="file" className="image-upload" value='' accept="image/*" />
                        </label>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='bg-f8f8f8 px-4 py-5 w-40-resp-mob box-shadow radius-11'>
                <div className='clr-202020 fw-500 f-xxs mb-3'>
                    Lorem ipsum dolor sit amet. Eum esse voluptatum quo velit nihil et enim. Eum esse voluptatum  
                </div>
                <div>
                    <button className='white-btn f-xs px-4 me-2 fw-500 mb-2 mb-lg-0'>Save Draft</button>
                    <button className='lightbrick-btn f-xs px-2'>Publish Product</button>
                </div>
           
            </div>
        </div>

 
      </div>
    );
  }

  componentDidMount(){
    setTimeout(() => {
        this.initNavAnim();
    }, 500);
  }
  initNavAnim(){
    function initImageUpload(box) {
        let uploadField = box.querySelector('.image-upload');
      
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
        
        function previewImage(file){
          let thumb = box.querySelector('.js--image-preview'),
              reader = new FileReader();
      
          reader.onload = function() {
            thumb.style.backgroundImage = 'url(' + reader.result + ')';
          }
          reader.readAsDataURL(file);
          thumb.className += ' js--no-default';
        }
      
        function checkType(file){
          let imageType = /image.*/;
          if (!file.type.match(imageType)) {
            console.log('Datei ist kein Bild');
          } else if (!file){
            console.log('Kein Bild gewählt');
          } else {
            previewImage(file);
          }
        }
        
      }
      
      // initialize box-scope
      var boxes = document.querySelectorAll('.box');
      
      for (let i = 0; i < boxes.length; i++) {
        let box = boxes[i];
        initDropEffect(box);
        initImageUpload(box);
      }
      
      
      
      /// drop-effect
      function initDropEffect(box){
        let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
        
        // get clickable area for drop effect
        area = box.querySelector('.js--image-preview');
        area.addEventListener('click', fireRipple);
        
        function fireRipple(e){
          area = e.currentTarget
          // create drop
          if(!drop){
            drop = document.createElement('span');
            drop.className = 'drop';
            this.appendChild(drop);
          }
          // reset animate class
          drop.className = 'drop';
          
          // calculate dimensions of area (longest side)
          areaWidth = getComputedStyle(this, null).getPropertyValue("width");
          areaHeight = getComputedStyle(this, null).getPropertyValue("height");
          maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));
      
          // set drop dimensions to fill area
          drop.style.width = maxDistance + 'px';
          drop.style.height = maxDistance + 'px';
          
          // calculate dimensions of drop
          dropWidth = getComputedStyle(this, null).getPropertyValue("width");
          dropHeight = getComputedStyle(this, null).getPropertyValue("height");
          
          // calculate relative coordinates of click
          // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
          x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
          y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
          
          // position drop and animate
          drop.style.top = y + 'px';
          drop.style.left = x + 'px';
          drop.className += ' animate';
          e.stopPropagation();
          
        }
      }
      
  }

}

export default Addproduct