import React, { Component } from 'react'
import aboutusimg from '../../images/Aboutus/aboutusimg.svg'
import mission from '../../images/Aboutus/mission.svg'
import vission from '../../images/Aboutus/vission.svg'
import keepintouch from '../../images/Aboutus/keepintouch.svg'
// import frontimg from '../../images/Aboutus/frontimg.png'
import fish from '../../images/Aboutus/fish.svg'
import meat from '../../images/Aboutus/meat.svg'
import crab from '../../images/Aboutus/crab.svg'
import './Aboutus.css'

export class Aboutus extends Component {
  render() {
    return (
      <div>

        <div className='w-100 banner-img mb-5'>
            <div className='lh-33 mb-3'>
            <b className='f-40 black-clr'>Discover the best in </b><br></br>
            <span className='brick-clr f-40 ff-carousaly'><b>fresh meats and</b></span><br></br>
            <span className='brick-clr f-40 ff-carousaly' me-2><b>seafood</b></span><span className='f-40 black-clr ms-3'><b>at our shop</b></span>
            </div>

            <div className='f-sm clr-868790 fw-500'>
            Lorem ipsum dolor sit amet. Vel illum maiores hic<br></br> dolore qui reprehenderit odit aut dolores illum.
            </div>

            <button className='lightbrick-btn mt-3'>Show Now <i className="fa-solid fa-chevron-right"></i></button>
        </div>

        <div className='w-100 d-flex flex-column-reverse flex-lg-row justify-content-between mb-3 mb-lg-0'>
            <div className='w-50-resp-mob'>
                <div className='brick-clr mb-4 f-md'><b>SIBU FISH N’ MEAT</b></div>
                <div className='clr-868790 f-sm lh-33 fw-500 text-justify text-lg-start'>
                At our fish and meat shop, we are dedicated to providing
                our customers with the freshest and highest quality 
                seafood and meat products. With a passion for exceptional
                taste and unparalleled customer service, we have built a
                reputation for being the go-to destination for discerning
                food lovers.<br></br>
                We source our products from trusted local suppliers and 
                ensure that all of our meats and fish are hand-selected for
                their freshness and flavor. 
                </div>
            </div>
            <div className='w-50-resp-mob text-end'>
                <img src={ aboutusimg } alt='' className='aboutusimgsize'></img>
            </div>
        </div>

        <div className='w-100 d-flex flex-column flex-lg-row missionvission'>
            <div className='w-50-mob-resp box-shadow bg-white py-3 p-5 radius-11 me-0 me-lg-3 d-flex flex-column flex-lg-row align-items-center mb-3 mb-lg-0'>
                <img src={ mission } alt='' className='mb-2 mb-lg-0'></img>
                <div className='ms-0 ms-lg-5'>
                    <div className='fw-600 text-center text-lg-start'>Mission</div>
                    <div className='f-xs mt-2 text-center text-lg-start'>
                    Lorem ipsum dolor sit amet. Vel illum mai
                    ores hic dolore qui reprehenderit odit aut
                    dolores illum.
                    </div>
                </div>
            </div>
            <div className='w-50-mob-resp box-shadow  bg-white py-3 p-5 radius-11 me-2 d-flex flex-column flex-lg-row align-items-center'>
                <img src={ vission } alt='' className='mb-2 mb-lg-0'></img>
                <div className='ms-0 ms-lg-5'>
                    <div className='fw-600 text-center text-lg-start'>Vision</div>
                    <div className='f-xs mt-2 text-center text-lg-start'>
                    Lorem ipsum dolor sit amet. Vel illum mai
                    ores hic dolore qui reprehenderit odit aut
                    dolores illum.
                    </div>
                </div>
            </div>
        </div>

        <div className='ousrservices w-100 mt-5 my-lg-5'>
            <div className='mb-100 text-center fw-600 black-clr'>Our Services </div>
            <div className='d-flex flex-column flex-lg-row'>
                <div className='item'>
                    <div className='absolute img-bg'><img src={ fish } alt=''></img></div>
                    <div className='fw-600 black-clr mb-2'>Fresh Fish</div>
                    <div className='f-xs clr-868790'>
                        Lorem ipsum dolor sit amet. Vel
                        illum maiores hic dolore qui repr
                        ehenderit odit aut dolores illum.
                    </div>
                    <button className='lightbrick-btn f-xs mt-4 px-3'>View</button>
                </div>
                <div className='mx-0 mx-lg-4 item'>
                    <div className='absolute img-bg'><img src={ meat } alt=''></img></div>
                    <div className='fw-600 black-clr mb-2'>Meat</div>
                    <div className='f-xs clr-868790'>
                        Lorem ipsum dolor sit amet. Vel
                        illum maiores hic dolore qui repr
                        ehenderit odit aut dolores illum.
                    </div>
                    <button className='lightbrick-btn f-xs mt-4 px-3'>View</button>
                </div>
                <div className='item'>
                    <div className='absolute img-bg'><img src={ crab } alt=''></img></div>
                    <div className='fw-600 black-clr mb-2'>Seashell</div>
                    <div className='f-xs clr-868790'>
                        Lorem ipsum dolor sit amet. Vel
                        illum maiores hic dolore qui repr
                        ehenderit odit aut dolores illum.
                    </div>
                    <button className='lightbrick-btn f-xs mt-4 px-3'>View</button>
                </div>
            </div>
        </div>

        <div className='keepintouch bg-F7EBEC my-4 radius-11 py-3 px-5 d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center'>
            <div className=''>
                <div className='fw-600 f-lg'>Keep in Touch With Us!</div>
                <div className='f-xs clr-868790 mt-2 mb-3'>Please press on below button  to explore our products.</div>
                <div>
                    <button className='lightbrick-btn px-4 f-sm me-2'>Sign Up</button>
                    <button className='white-btn px-4 f-sm'>Login</button>
                </div>
            </div>
            <div>
                <img src={ keepintouch } alt='' className='keepintouchimg'></img>
            </div>
        </div>
      </div>
    )
  }
}

export default Aboutus