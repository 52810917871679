import React, { Component } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import './Checkout.css'
import $ from 'jquery';
import fishimg from '../../images/fish.jpg'

export class Checkout extends Component {
  componentDidMount() {
    var buttonPlus  = $(".qty-btn-plus");
var buttonMinus = $(".qty-btn-minus");

var incrementPlus = buttonPlus.click(function() {
  var $n = $(this)
  .parent(".qty-container")
  .find(".input-qty");
  $n.val(Number($n.val())+1 );
});

var incrementMinus = buttonMinus.click(function() {
  var $n = $(this)
  .parent(".qty-container")
  .find(".input-qty");
  var amount = Number($n.val());
  if (amount > 0) {
    $n.val(amount-1);
  }
});
  }
  render() {

    return (
      <div className='checkout'>
        <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
          <ProgressBar percent={75}>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${accomplished ? "accomplished" : null}`}
                >
                  {index + 1}
                </div>
              )}
            </Step>
          </ProgressBar>
        </div>
        
        <div className='d-flex flex-column flex-lg-row w-100 mt-4'>
          <div className='w-60-resp-mob radius-11 box-shadow p-3 me-0 me-lg-3 bg-white'>
            <div className='cart-table-head w-100 d-none d-lg-flex bb-dashed f-xs fw-500 pb-2'>
                <div className='w-50'>5/4 Items Selected</div>
                <div className='w-25'>Quantity</div>
                <div className='w-25'>Total</div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            <div className='cart-table-item w-100 d-flex flex-column flex-lg-row bb-dashed f-xs fw-500 py-2'>
                <div className='w-50-resp-mob d-flex mb-3 mb-lg-0'>
                  <img src={fishimg} alt='' className='prod-img'></img>
                  <div className='ms-3'>
                    <div className='f-13 fw-600'>beef shoulder chunks diced</div>
                    <div className='d-flex justify-content-between my-1'>
                      <div className='brick-clr bg-F9F9F9 p-1 radius-7'><strong>£15/box</strong></div>
                      <div className='f-xxs fw-600 radius-7 bg-F9F9F9 p-1'>2kg (1bag)</div>
                    </div>
                    <div className='f-xxs clr-898989'><i class="fa-solid fa-reply f-xxs me-1"></i>2 days return available </div>
                  </div>
                </div>
                <div className='w-50-resp-mob d-flex'>
                  <div className='w-50-resp-mob mb-3 mb-lg-0'>
                  <div className="qty-container">
                    <button className="qty-btn-minus  btn-cornered" type="button"><i className="fa fa-minus"></i></button>
                    <input type="text" name="qty" value="0" className="input-qty input-cornered"/>
                    <button className="qty-btn-plus  btn-cornered" type="button"><i className="fa fa-plus"></i></button>
                  </div>
                  </div>
                  <div className='w-50-resp-mob d-flex justify-content-between'>
                    <div className='brick-clr fw-600'>£44(£22/kg)</div>
                    <div><i class="fa-regular fa-circle-xmark clr-898989"></i></div>
                  </div>
                </div>
            </div>
            
          </div>
          <div className='w-40-resp-mob radius-11 box-shadow p-3 bg-white'>
            <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
                <strong>
                    Ordering with ease,<br></br>
                    delivering with <br></br>
                    <span className='brick-clr'>pleasure</span>
                </strong>
            </div>
            <div className='py-3 px-5'>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Price Details</div>
                  <div className='w-50'>7 Items</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total</div>
                  <div className='w-50'>45(15/kg)</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Discount</div>
                  <div className='w-50'>No Discount</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Convinience Fee</div>
                  <div className='w-50'>99 Free</div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                  <div className='w-50 clr-565B67'>Total Amount</div>
                  <div className='w-50 brick-clr'><strong>45(15/kg)</strong></div>
                </div>
              </div>
            </div>
            <div className='py-1 px-5'>
            <button className='lightbrick-btn f-xs w-100 mx-auto'>Place your order</button>
            </div>

            <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline'>Contact Us</span></div>
           
          </div>
        </div>
      </div>
    )
  }
}

export default Checkout
