import React, { Component } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import './Cartaddress.css'
// import $ from 'jquery';

export class Cartaddress extends Component {
  render() {
    return (
        <div className='checkout cartsddress'>
          <div className='my-5 mx-0 mx-lg-5 d-flex justify-content-center'>
            <ProgressBar percent={75}>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>
          
          <div className='d-flex w-100 mt-4 flex-column flex-lg-row'>
            <div className='w-60-resp-mob radius-11 box-shadow p-3 me-3 bg-white mb-3 mb-lg-0'>
              <div className='d-flex justify-content-between w-100 mb-2'>
                <div className='fw-500 f-xs'>Add shipping Address</div>
                <button><i class="fa-solid fa-pencil"></i></button>
              </div>

              <div className='d-flex justify-content-between w-100 mb-3'>
              <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='First Name'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Last Name'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='Phone Number'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Email Address'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='Address(Area and street)'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='Postal Code'></input>
              </div>
              <div className='d-flex justify-content-between w-100 mb-3'>
                <input className='w-50 inp-F0F0F0 p-2 me-3 f-xs' placeholder='City/District/Town'></input>
                <input className='w-50 inp-F0F0F0 p-2 f-xs' placeholder='State'></input>
              </div>
              <div>
              <div className='fw-500 f-xs mb-2'>Address Type</div>
              <div className='d-flex flex-column flex-lg-row'>
                <div className="comment-form-cookies-consent d-flex mb-2 me-3">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Home Delivery</label>
                </div>
                <div className="comment-form-cookies-consent d-flex mb-2">
                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="radio" value="yes"></input>
                    <label for="wp-comment-cookies-consent" className='mb-0 f-13 fw-500 black-clr'>Work ( Delivery between 10 to 5)</label>
                </div>
              </div>
              </div>
              <div className='d-flex justify-content-end mt-3'>
                <button className='lightbrick-btn f-13 fw-500 me-3 px-2'>Save Address</button>
                <button className='white-btn f-13 fw-500 px-3'>Cancel</button>
              </div>
              
            </div>
            <div className='w-40-resp-mob radius-11 box-shadow p-3 bg-white'>
              <div className='scootyman fw-500 f-lg d-flex align-items-center bb-dashed'>
                  <strong>
                      Ordering with ease,<br></br>
                      delivering with <br></br>
                      <span className='brick-clr'>pleasure</span>
                  </strong>
              </div>
              <div className='py-3 px-5'>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Price Details</div>
                    <div className='w-50'>7 Items</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Total</div>
                    <div className='w-50'>45(15/kg)</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Discount</div>
                    <div className='w-50'>No Discount</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Convinience Fee</div>
                    <div className='w-50'>99 Free</div>
                  </div>
                </div>
                <div className='mb-2'>
                  <div className='w-100 d-flex f-xs justify-content-between fw-500'>
                    <div className='w-50 clr-565B67'>Total Amount</div>
                    <div className='w-50 brick-clr'><strong>45(15/kg)</strong></div>
                  </div>
                </div>
              </div>
              <div className='py-1 px-5'>
              <button className='lightbrick-btn f-xs w-100 mx-auto'>Place your order</button>
              </div>
  
              <div className='clr-898989 fw-500 f-xs mt-2 d-flex justify-content-center'>Need help? <span className='underline'>Contact Us</span></div>
             
            </div>
          </div>

           <div className='mt-3'>
           <div className='w-60-resp-mob radius-11 box-shadow p-3 me-3 bg-white'>
                <div className='w-100 d-flex flex-column flex-lg-row'>
                    <div className='w-60-resp-mob d-flex'>
                        <button className='white-btn me-2'><i class="fa-solid fa-pencil"></i></button>
                        <div>
                            <div className='brick-clr fw-500 f-xs'><span>Deliver To :</span><span>Kelly Clarkson</span><span className='bg-d5d5d5 ms-2 black-clr py-1 px-2 radius-7'>Home</span></div>
                            <div className='f-xxs clr-898989 fw-500 mt-2'>46 London Road, Colmworth MK44 7ER</div>
                            <input className='inp-F5F5F5-outline px-2 py-1 f-13 fw-500 mt-3' placeholder='Enter Postal Code'></input>

                        </div>

                    </div>
                    <div className='w-40-resp-mob text-end  mt-3 mt-lg-0 f-13'>
                        <button className='lightbrick-btn mb-3 fw-500'>Deliver Here</button>
                    </div>
                </div>
            </div>
           </div>
        </div>
      )
  }
}

export default Cartaddress