import React, { Component } from 'react'
import './Orders.css'
import { Link } from 'react-router-dom'


export class Orders extends Component {
  render() {
    return (
      <div className='px-2 py-3'>
        <div className='text-end'>
            {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
            <Link to='/Layout2/Orderdetails' className='lightbrick-btn f-xs px-4 add-prod-link'>Today's Order</Link>
        </div>

        <div className='d-flex w-100 search-item my-3 align-items-center p-2'>
          
          <div className='relative w-90'>
            <input placeholder='Start typing search for products ' className='w-100 transparent-inp f-xs pe-4'></input>
          </div>
          <button className='search-item-btn'><i className="fa-solid fa-magnifying-glass"></i></button>
        </div>

        <div className='w-100 product-table'>
        <table>
          <thead>
            <tr>
              <th className='w-10'>
                <div className="comment-form-cookies-consent d-flex">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0'>Number</label>
                </div>
              </th>
              <th className='w-10'>Date</th>
              <th className='w-20'>Customer</th>
              <th className='w-10'>Paid</th>
              <th className='w-10'>Status</th>
              <th className='w-10'>Items</th>
              <th className='w-10'>Total</th>
              <th className='w-5'>More</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='d-flex align-items-center w-100'>
                <div className="comment-form-cookies-consent">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0 fw-500 f-13'>#02A45</label>
                </div>
              </td>
              <td className='fw-500 f-13'>Dec-22</td>
              <td className='fw-500 f-13'>Edvin John Xavier</td>
              <td className='fw-500 f-13'>No</td>
              <td className='fw-500 f-13'>New</td>
              <td className='fw-500 f-13'>3</td>
              <td className='fw-500 f-13'><span className='me-1'><i className="fa-solid fa-sterling-sign"></i></span><span>453.18</span></td>
              <td className='fw-500'><button><i className="fa-solid fa-ellipsis-vertical f-md"></i></button></td>

            </tr>
            
          </tbody>
        </table>
        </div>
      </div>
    )
  }
}

export default Orders