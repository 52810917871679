import React, { Component } from 'react'
import img1 from '../../images/Orders/meatimg.webp'
import img2 from '../../images/Orders/img1.svg'
import './Orderdetails.css'


export class Orderdetails extends Component {
  render() {
    return (
      <div>
        <div className='bg-white box-shadow radius-7 p-2 d-flex w-100 justify-content-between align-items-center mb-4'>
            <div>
                <div className='fw-600 mb-2 f-xs'><span>Order  <i className="fa-solid fa-hashtag"></i></span><span>76854</span></div>
                <div className='f-xxs clr-565B67 fw-500'>
                    <span>October 7, 2020</span>
                    <span className='mx-1'>at</span>
                    <span>9:08 pm</span>
                    <span className='mx-1'>|</span>
                    <span className='me-1'>6</span><span>items</span>
                    <span className='mx-1'>|</span>
                    <span className='me-1'>Total</span><span>$</span><span>5,678.0</span>
                    <span className='mx-1'>|</span>
                    <span className='me-1 outstock  black-clr'>Paid</span>
                    <span className='instock black-clr'>Partially Fulfilled</span>
                </div>
            </div>
            <div className='f-13'>
                <button className='white-btn fw-500 px-3 me-2'>Track Order</button>
                <button className='lightbrick-btn fw-500 px-3'>Delete</button>
            </div>
        </div>
        <div className='d-flex w-100'>
            <div className='bg-white box-shadow radius-7 py-2 w-60 me-3'>
                <div className='fw-600 f-sm mb-4 px-3'>Items</div>
                <div>
                    <div className='w-100 d-flex align-items-center px-3 dotted-border-btm py-3'>
                        <div className='d-flex align-items-center w-50'>
                            <div><img src={img1} className='Orderedimg' alt=''></img></div>
                            <div className='fw-600 f-13 ms-2'>Wild Pork Diced (with bone & skin)</div>
                        </div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                        <div className='f-xs clr-565B67 w-10 text-end'>1</div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                    </div>
                    <div className='w-100 d-flex align-items-center px-3 dotted-border-btm py-3'>
                        <div className='d-flex align-items-center w-50'>
                            <div><img src={img1} className='Orderedimg' alt=''></img></div>
                            <div className='fw-600 f-13 ms-2'>Wild Pork Diced (with bone & skin)</div>
                        </div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                        <div className='f-xs clr-565B67 w-10 text-end'>1</div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                    </div>
                    <div className='w-100 d-flex align-items-center px-3 dotted-border-btm py-3'>
                        <div className='d-flex align-items-center w-50'>
                            <div><img src={img1} className='Orderedimg' alt=''></img></div>
                            <div className='fw-600 f-13 ms-2'>Wild Pork Diced (with bone & skin)</div>
                        </div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                        <div className='f-xs clr-565B67 w-10 text-end'>1</div>
                        <div className='f-xs clr-565B67 w-20 text-end'><i className="fa-solid fa-sterling-sign"></i>67.90</div>
                    </div>
                </div>
                <div className='w-100 f-13 fw-600 dotted-border-btm py-3'>
                    <div className='d-flex justify-content-between px-3 '>
                        <div>
                            <div>Subtotal</div>
                        </div>
                        <div>
                            <div><i className="fa-solid fa-sterling-sign"></i>5,877.00</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between px-3 my-2'>
                        <div>
                            <div>Store Credit </div>
                        </div>
                        <div>
                            <div><i className="fa-solid fa-sterling-sign"></i>5,877.00</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between px-3 '>
                        <div>
                            <div>Delivery Charge</div>
                        </div>
                        <div>
                            <div><i className="fa-solid fa-sterling-sign"></i>5,877.00</div>
                        </div>
                    </div>
                    
                </div>
                <div className='w-100 f-xs fw-600 py-3'>
                    <div className='d-flex justify-content-between px-3 '>
                        <div>
                            <div>Total</div>
                        </div>
                        <div>
                            <div><i className="fa-solid fa-sterling-sign"></i>5,877.00</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='w-40'>
                <div className='bg-white box-shadow radius-7 p-3'>
                    <div className='d-flex f-xs fw-600 w-100 justify-content-between mb-2'>
                        <div>Customer</div>
                        <button className='fw-600'>Edit</button>
                    </div>
                    <div className='d-flex align-items-center'>
                        <img src={img2} className='order-profpic me-2' alt=''></img>
                        <div className=''>
                            <div className='f-xs fw-600'>Jessica Moore</div>
                            <div className='f-xxs fw-500'>This is a first order</div>
                        </div>
                        
                    </div>
                </div>

                <div className='bg-white box-shadow radius-7 p-3 my-5'>
                    <div className='d-flex f-xs fw-600 w-100 justify-content-between mb-2'>
                        <div>Contact</div>
                        <button className='fw-600'>Edit</button>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='f-13 fw-500 w-40'>Jessica Moore</div>
                        <div className='f-13 fw-500 w-60'>jessicamoore@gmail.com</div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='f-13 fw-500 w-40'>Phone Number</div>
                        <div className='f-13 fw-500 w-60'>077 3183 9595</div>
                    </div>
                </div>

                <div className='bg-white box-shadow radius-7 p-3'>
                    <div className='d-flex f-xs fw-600 w-100 justify-content-between mb-2'>
                        <div>Shipping Address</div>
                        <button className='fw-600'>Edit</button>
                    </div>
                    <div className='mb-2'>
                        <div className='brick-clr fw-600 mb-1 f-xs'>Primary address</div>
                        <div className='f-13 fw-500'>
                            <div>Jessica Moore</div>
                            <div>82 Jedburgh Road,Levishie</div>
                            <div>IV3 4SW, United Kingdom</div>
                        </div>
                    </div>
                    <div>
                        <div className='brick-clr fw-600 mb-1 f-xs'>Secondary address</div>
                        <div className='f-13 fw-500'>
                            <div>Jessica Moore</div>
                            <div>82 Jedburgh Road,Levishie</div>
                            <div>IV3 4SW, United Kingdom</div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
      </div>
    )
  }
}

export default Orderdetails