import React, { Component } from 'react'

export class Newuser extends Component {
  render() {
    return (
      <div className='px-4'>
        <div className='f-md fw-500 mb-4'><i class="fa-solid fa-circle-arrow-left me-2"></i>New Customers</div>
        <div>
        <table>
          <thead className='radius-11'>
            <tr className='brick-clr fw-600 f-15 bg-F7EBEC'>
              <th className='w-20'>
                <div className="comment-form-cookies-consent d-flex">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0'>Customer Name</label>
                </div>
              </th>
              <th className='w-20'>Place</th>
              <th className='w-15'>Phone</th>
              <th className='w-20'>Email</th>
              <th className='w-15'>Order Placed</th>
              <th className='w-10'>More</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='d-flex align-items-center w-100'>
                <div className="comment-form-cookies-consent">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0 fw-500 f-12'>Edvin John Xavier</label>
                </div>
              </td>
              <td className='fw-500 f-12'>London</td>
              <td className='fw-500 f-12'>077 3922 5112</td>
              <td className='fw-500 f-12'>highspeed@mail.com</td>
              <td className='fw-500 f-12 fw-600'>2 items</td>
              <td className='fw-500'><button><i className="fa-solid fa-ellipsis-vertical f-md"></i></button></td>
            </tr>

            <tr>
              <td className='d-flex align-items-center w-100'>
                <div className="comment-form-cookies-consent">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0 fw-500 f-12'>Edvin John Xavier</label>
                </div>
              </td>
              <td className='fw-500 f-12'>London</td>
              <td className='fw-500 f-12'>077 3922 5112</td>
              <td className='fw-500 f-12'>highspeed@mail.com</td>
              <td className='fw-500 f-12 fw-600'>2 items</td>
              <td className='fw-500'><button><i className="fa-solid fa-ellipsis-vertical f-md"></i></button></td>
            </tr>

            <tr>
              <td className='d-flex align-items-center w-100'>
                <div className="comment-form-cookies-consent">
                  <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes"></input>
                  <label for="wp-comment-cookies-consent" className='mb-0 fw-500 f-12'>Edvin John Xavier</label>
                </div>
              </td>
              <td className='fw-500 f-12'>London</td>
              <td className='fw-500 f-12'>077 3922 5112</td>
              <td className='fw-500 f-12'>highspeed@mail.com</td>
              <td className='fw-500 f-12 fw-600'>2 items</td>
              <td className='fw-500'><button><i className="fa-solid fa-ellipsis-vertical f-md"></i></button></td>
            </tr>

           
            
          </tbody>
        </table>
        </div>
      </div>
    )
  }
}

export default Newuser
