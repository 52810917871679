import React from 'react'
import { Link } from 'react-router-dom'
import './Login.css';


export default function Login() {
    return (
        <div className='bg-white box-shadow p-5 w-30 ff-mon m-auto radius-11 zindex1'>
            <div className='f-xl black-clr mb-2 ff-dmsans'><b>Login In</b></div>

            <form>
                <div className='mb-2'>
                    <div className='lightgrey-clr f-xxs text-start'>USERNAME</div>
                    <div><input className='inp-F5F5F5 radius-7 p-2 clr-202020 f-xs w-100' value='olivertwist01'></input></div>
                </div>
                <div className='mb-1'>
                    <div className='lightgrey-clr f-xxs text-start'>PASSWORD</div>
                    <div className='relative'>
                        <input className='inp-F5F5F5 radius-7 clr-202020 p-2 f-xs w-100' type='password'></input>
                        <div className='absolute position-middle-r'><i className="fa-regular fa-eye brick-clr"></i></div>

                    </div>
                </div>
                <div className='mb-3'>
                    <Link to="/registration" className='black-clr underline-none f-xs forgetpass-anc'>Forgot your password?</Link>
                </div>
                <div>
                    <button className='brick-btn f-xs w-100'>LOGIN IN</button>
                </div>
            </form>

            <div className='d-flex w-100 justify-content-center mt-4 mb-2'>
                <button className='sm-btns'><i className="fa-brands fa-google f-lg clr-202020"></i> </button>
                <button className='mx-3 sm-btns'><i className="fa-brands fa-twitter f-lg clr-202020"></i></button>
                <button className='sm-btns'><i className="fa-brands fa-facebook-f f-lg clr-202020"></i></button>
            </div>
            <div><span className='lightgrey-clr f-xs'>Don't have an account, yet?</span><span className='ms-2'><Link to="/layout2" className='brick-clr f-xs loginlink-anc'>Sign Up</Link></span></div>


        </div>
    )
}
