import React, { Component } from 'react'
import './Coupon.css'
import { Link } from 'react-router-dom'
import $ from 'jquery';


export class Coupon extends Component {
  componentDidMount() {
    $("#search").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        return true
      });
    });
  }
  render() {
    
    return (
      <div className='px-0 px-md-4 py-3 coupon customer'>
        <div className='text-end'>
            {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
            <Link to='/Layout2/Maintanance' className='lightbrick-btn f-xs px-4 add-prod-link'>New Coupon</Link>
        </div>

        <div className='d-flex align-items-center my-3'>
            <div className='relative w-100'>
                <input className='nav-search-inp w-100 fw-600' placeholder='Start typing search for coupon' id='search'></input>
                <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
            </div>
        </div>

        <div className='w-100 product-table'>
          <table class="rwd-table mb-2">
            <tbody id='table'>
              <tr>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Discount</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th className='text-center'>More</th>
              </tr>
              <tr>
                  <td data-th="Code">
                  FIVESTARS
                  </td>
                  <td data-th="Type">
                  Percentage
                  </td>
                  <td data-th="Discount"  className='lightbrick fw-600'>
                  50%
                  </td>
                  <td data-th="Status">
                  Finished
                  </td>
                  <td data-th="Start Date">
                  20-12-2023
                  </td>
                  <td data-th="End Date" className='lightbrick fw-600'>
                  28-12-2023                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Code">
                  FIVESTARS
                  </td>
                  <td data-th="Type">
                  Percentage
                  </td>
                  <td data-th="Discount"  className='lightbrick fw-600'>
                  50%
                  </td>
                  <td data-th="Status">
                  Finished
                  </td>
                  <td data-th="Start Date">
                  20-12-2023
                  </td>
                  <td data-th="End Date" className='lightbrick fw-600'>
                  28-12-2023                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Code">
                  FIVESTARS
                  </td>
                  <td data-th="Type">
                  Percentage
                  </td>
                  <td data-th="Discount"  className='lightbrick fw-600'>
                  50%
                  </td>
                  <td data-th="Status">
                  Finished
                  </td>
                  <td data-th="Start Date">
                  20-12-2023
                  </td>
                  <td data-th="End Date" className='lightbrick fw-600'>
                  28-12-2023                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Code">
                  FIVESTARS
                  </td>
                  <td data-th="Type">
                  Percentage
                  </td>
                  <td data-th="Discount"  className='lightbrick fw-600'>
                  50%
                  </td>
                  <td data-th="Status">
                  Finished
                  </td>
                  <td data-th="Start Date">
                  20-12-2023
                  </td>
                  <td data-th="End Date" className='lightbrick fw-600'>
                  28-12-2023                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Code">
                  FIVESTARS
                  </td>
                  <td data-th="Type">
                  Percentage
                  </td>
                  <td data-th="Discount"  className='lightbrick fw-600'>
                  50%
                  </td>
                  <td data-th="Status">
                  Finished
                  </td>
                  <td data-th="Start Date">
                  20-12-2023
                  </td>
                  <td data-th="End Date" className='lightbrick fw-600'>
                  28-12-2023                  
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
            
           
             
            </tbody>
          </table> 
        </div>
      </div>
    )
  }
}

export default Coupon