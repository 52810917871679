import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
import './Layout4.css';
import Toolbar from '../Toolbar/Toolbar';


export class layout4 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className='w-100 ff-mon'>
            <Toolbar></Toolbar>
            <div className='w-100 d-flex flex-column p-4'>
              <Outlet />
            </div>
        </div>

      </React.Fragment>
    )
  }
}

export default layout4