import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import $ from 'jquery';

export class Purchase extends Component {
  componentDidMount() {
    $("#search").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        return true
      });
    });
  }
render() {
    return (
        <div className='px-2 px-lg-4 py-3 sales customer'>
            <div className='d-flex justify-content-between flex-column flex-md-row'>
              <div className='fw-600 mb-2 mb-md-0'>Purchase List</div>
                {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
                <div className=''>
                <Link to='/Layout2/Createpurchase' className='lightbrick-btn f-xs px-4 h-33px add-prod-link me-2'><i class="fa-solid fa-plus me-2"></i>Create Purchase</Link> 
                {/* <button className='brick-btn f-xs px-2'><i class="fa-solid fa-download me-1"></i>Dowload all</button> */}
                </div>
            </div>

            <div className='d-flex align-items-center my-3'>
                <div className='relative w-50'>
                    <input className='nav-search-inp w-100 fw-600 d-none d-lg-block' placeholder='Start typing search for Sales' id='search'></input>
                    <input className='nav-search-inp w-100 fw-600 d-block d-lg-none' placeholder='Search' id='search'></input>
                    <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
                </div>
                <div className='w-50 text-end mx-2'>
                    <input type='text' className='w-100 nav-search-inp ps-2' placeholder='Purchase date'
                    onChange={(e) => console.log(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    ></input>
                </div>
                <div className='w-50 text-end'>
                    <input type='text' className='w-100 nav-search-inp ps-2' placeholder='Delivery date'
                    onChange={(e) => console.log(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    ></input>
                </div>
            </div>

            <div className='product-table tbl_scroll'>
                <table>
                    <thead>
                        <tr className='bg-white'>
                            <th className='fw-600 f-13'>ORDER NO</th>
                            <th className='fw-600 f-13'>SUPPLIER NAME</th>
                            <th className='fw-600 f-13'>LOCATION</th>
                            <th className='fw-600 f-13'>ORDER DATE</th>
                            <th className='fw-600 f-13'>DELIVERED DATE</th>
                            <th className='fw-600 f-13'>PURCHASED DATE</th>
                            <th className='fw-600 f-13'>ITEMS</th>
                            <th className='fw-600 f-13'>TOTAL USERS</th>
                            <th className='fw-600 f-13'>ORDRED QTY</th>
                            <th className='fw-600 f-13'>AVAILABLE QTY</th>
                            <th className='fw-600 f-13'>TOTAL QTY</th>
                            <th className='fw-600 f-13'>TOTAL AMOUNT</th>
                            <th className='fw-600 f-13'>PURCHASED COST</th>
                            <th className='fw-600 f-13'>ACTION</th>
                        </tr>
                    </thead>
                    <tbody id='table'>
                       
                        <tr>
                            <td className='fw-500 f-xs'>#02A45</td>
                            <td className='fw-500 f-xs brick-clr'>ABC Market</td>
                            <td className='fw-500 f-xs'>Manchester</td>
                            <td className='fw-500 f-xs'>10/10/2023</td>
                            <td className='fw-500 f-xs'>05/10/2023</td>
                            <td className='fw-500 f-xs'>01/10/2023</td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModalUser" className='underline fw-600 brick-clr'>8 Users</button></td>
                            <td className='fw-500 f-xs'>4 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500'>
                                <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                <button><i class="fa-solid fa-download clr-33363F"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-500 f-xs'>#02A45</td>
                            <td className='fw-500 f-xs brick-clr'>ABC Market</td>
                            <td className='fw-500 f-xs'>Manchester</td>
                            <td className='fw-500 f-xs'>10/10/2023</td>
                            <td className='fw-500 f-xs'>05/10/2023</td>
                            <td className='fw-500 f-xs'>01/10/2023</td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModalUser" className='underline fw-600 brick-clr'>8 Users</button></td>
                            <td className='fw-500 f-xs'>4 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500'>
                                <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                <button><i class="fa-solid fa-download clr-33363F"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-500 f-xs'>#02A45</td>
                            <td className='fw-500 f-xs brick-clr'>ABC Market</td>
                            <td className='fw-500 f-xs'>Manchester</td>
                            <td className='fw-500 f-xs'>10/10/2023</td>
                            <td className='fw-500 f-xs'>05/10/2023</td>
                            <td className='fw-500 f-xs'>01/10/2023</td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModalUser" className='underline fw-600 brick-clr'>8 Users</button></td>
                            <td className='fw-500 f-xs'>4 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500'>
                                <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                <button><i class="fa-solid fa-download clr-33363F"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-500 f-xs'>#02A45</td>
                            <td className='fw-500 f-xs brick-clr'>ABC Market</td>
                            <td className='fw-500 f-xs'>Manchester</td>
                            <td className='fw-500 f-xs'>10/10/2023</td>
                            <td className='fw-500 f-xs'>05/10/2023</td>
                            <td className='fw-500 f-xs'>01/10/2023</td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModalUser" className='underline fw-600 brick-clr'>8 Users</button></td>
                            <td className='fw-500 f-xs'>4 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500'>
                                <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                <button><i class="fa-solid fa-download clr-33363F"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-500 f-xs'>#02A45</td>
                            <td className='fw-500 f-xs brick-clr'>ABC Market</td>
                            <td className='fw-500 f-xs'>Manchester</td>
                            <td className='fw-500 f-xs'>10/10/2023</td>
                            <td className='fw-500 f-xs'>05/10/2023</td>
                            <td className='fw-500 f-xs'>01/10/2023</td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                            <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModalUser" className='underline fw-600 brick-clr'>8 Users</button></td>
                            <td className='fw-500 f-xs'>4 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>3 Kg</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500 f-xs'>£ 460.02</td>
                            <td className='fw-500'>
                                <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                <button><i class="fa-solid fa-download clr-33363F"></i></button>
                            </td>
                        </tr>
                        

                    </tbody>
                </table>
            </div>
            {/* Popup Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-sm" role="document">
    <div className="modal-content">
 
      <div className="modal-body">
        <div className='fw-600 mb-3 bb-dashed'>Items</div>
        <div>
          <div className='w-50 fw-600 f-xs mb-2 brick-clr'>Seabass (Cleaned)</div>
          <div className='w-50 fw-600 f-xs mb-2 brick-clr'>Beef (Cleaned)</div>
          <div className='w-50 fw-600 f-xs mb-2 brick-clr'>Mutton (Cleaned)</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="exampleModalUser" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-sm" role="document">
    <div className="modal-content">
 
      <div className="modal-body">
        <div className='fw-600 mb-3 bb-dashed brick-clr'>Customer Name</div>
        <div>
          <div className='w-50 fw-600 f-xs mb-2'>Elon Harrison</div>
          <div className='w-50 fw-600 f-xs mb-2'>Ryan Stegall</div>
          <div className='w-50 fw-600 f-xs mb-2'>Mary Thomas</div>
        </div>
      </div>
    </div>
  </div>
</div>
        </div>
    )
}
}

export default Purchase
