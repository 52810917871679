import React, { Component } from 'react'
import './Createsales.css'
export class Createsales extends Component {
  render() {
    return (
      <div className='createsales'>
        <div className='fw-600 f-sm mb-3'>Create Sale</div>
        <div className='bg-F9F9F9 radius-11 p-3 box-shadow mb-4'>
            <div className='fw-600 f-xs mb-3'>Customer Details</div>
            <div className='region-table mt-3'>
                        <table class="rwd-table mb-2">
                            <tbody>
                            <tr>
                                <th>Order No</th>
                                <th>Customer</th>
                                <th>Location</th>
                                <th>Postal code</th>
                                <th>Order Date</th>
                                <th>Delivery Date</th>
                                <th>Delivery Status</th>
                                <th>Payment Status</th>
                            </tr>
                            <tr>
                                <td data-th="Order No">
                                    <input className='f-13 px-2 fw-500 inp-EFEFEF' value='#02A45'></input>
                                </td>
                                <td data-th="Customer">
                                    <input className='f-13 px-2 fw-500 inp-EFEFEF' value='Alex'></input>
                                </td>
                                <td data-th="Location">
                                <input className='f-13 px-2 fw-500 inp-EFEFEF' value='Manchester'></input>                                
                                </td>
                                <td data-th="Postal code">
                                <input className='f-13 px-2 fw-500 inp-EFEFEF' value='WWE123'></input>

                                
                                </td>
                                <td data-th="Order Date">
                                <input type='date' className='f-13 px-2 fw-500 inp-EFEFEF'></input>
                                </td>
                                <td data-th="Delivery Date">
                                <input type='date' className='f-13 px-2 fw-500 inp-EFEFEF'></input>
                                </td>
                                <td data-th="Delivery Status">
                                <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF'>
                                    <option value="Pending">Pending</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Transit">Transit</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Order Placed">Order Placed</option>
                                    <option value="Delivered">Delivered</option>
                                </select>
                                </td>
                                <td data-th="Payment Status">
                                <select name="cars" id="cars" className='f-13 px-2 fw-500 inp-EFEFEF'>
                                    <option value="Paid" className='fw-600 brick-clr'>Paid</option>
                                    <option value="Unpaid">Unpaid</option>
                                </select>
                                </td>
                            </tr>
                         
                           
                            </tbody>
                        </table>
                    </div>
        </div>

        <div className='bg-F9F9F9 radius-11 py-2 box-shadow mb-3'>
            <div className='bb-dashed py-4'>
                <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 1</div>
                <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Seabass'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs mb-1'>Ordered: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row px-4 w-100'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Available: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='45.03'></input>
                    </div>
                </div>
                
                
            </div>
            <div className='bb-dashed py-4'>
                <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 2</div>
                <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Seabass'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs mb-1'>Ordered: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row px-4 w-100'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Available: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='45.03'></input>
                    </div>
                </div>
                
                
            </div>
            <div className='bb-dashed py-4'>
                <div className='fw-600 f-xs brick-clr mb-2 px-4'>Product 3</div>
                <div className='d-flex flex-column flex-lg-row w-100 px-4 mb-3'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Product: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='Seabass'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs mb-1'>Ordered: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row px-4 w-100'>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Available: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='2kg'></input>
                    </div>
                    <div className='w-50-resp-mob mb-2 mb-lg-0'>
                        <div className='fw-500 f-xs me-2 mb-1'>Price: </div>
                        <input className='f-13 px-3 fw-500 inp-EFEFEF w-75-resp-mob' value='45.03'></input>
                    </div>
                </div>
                
                
            </div>
        </div>

        <div className='d-flex justify-content-end'>
            <div className='bg-F9F9F9 radius-11 p-3 w-25-resp-mob box-shadow'>
                <div className='bb-dashed py-2'>
                    <div><span className='fw-600 brick-clr f-xs me-3'>Total Quantity :</span><span className='fw-600 f-xs'>4 Kg</span></div>
                    <div><span className='fw-600 brick-clr f-xs me-3'>Total Amount :</span><span className='fw-600 f-xs'>£ 450.3</span></div>
                </div>
                <div className='py-3 d-flex text-center w-100'>
                    <button className='lightbrick-btn f-xs w-50 me-2'>Save</button>
                    <button className='white-btn f-xs w-50'>Cancel</button>
                </div>
            </div>
        </div>
      </div>
    );
  }

}

export default Createsales
