import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import img1 from '../../images/Invoice/img1.jpg';
import './Sales.css'
import $ from 'jquery';


export class Sales extends Component {
    componentDidMount() {
        $("#search").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $("#table tr").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            return true
          });
        });
      }
    render() {
        return (
            <div className='px-2 px-lg-4 py-3 sales customer'>
                <div className='d-flex justify-content-between flex-column flex-md-row'>
                  <div className='fw-600 mb-2 mb-md-0'>Sales List</div>
                    {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
                    <div className=''>
                    <Link to='/Layout2/Createsales' className='lightbrick-btn f-xs px-4 h-33px add-prod-link me-2'><i class="fa-solid fa-plus me-2"></i>Create Sale</Link> 
                    {/* <button className='brick-btn f-xs px-2'><i class="fa-solid fa-download me-1"></i>Dowload all</button> */}
                    </div>
                </div>

                <div className='d-flex align-items-center my-3'>
                    <div className='relative w-50'>
                        <input className='nav-search-inp w-100 fw-600 d-none d-lg-block' placeholder='Start typing search for customer' id='search'></input>
                        <input className='nav-search-inp w-100 fw-600 d-block d-lg-none' placeholder='Search' id='search'></input>
                        <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
                    </div>
                    <div className='w-50 text-end mx-2'>
                        <input type='text' className='w-100 nav-search-inp ps-2' placeholder='Order date'
                        onChange={(e) => console.log(e.target.value)}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        ></input>
                    </div>
                    <div className='w-50 text-end'>
                        <input type='text' className='w-100 nav-search-inp ps-2' placeholder='Delivery date'
                        onChange={(e) => console.log(e.target.value)}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        ></input>
                    </div>
                </div>

                <div className='product-table tbl_scroll'>
                    <table>
                        <thead>
                            <tr className='bg-white'>
                                <th className='fw-600 f-13'>ORDER NO</th>
                                <th className='fw-600 f-13'>CITY</th>
                                <th className='fw-600 f-13'>LOCATION</th>
                                <th className='fw-600 f-13'>CUSTOMER</th>
                                <th className='fw-600 f-13'>POSTAL CODE</th>
                                <th className='fw-600 f-13'>DELIVERY DATE</th>
                                <th className='fw-600 f-13'>ORDER DATE</th>
                                <th className='fw-600 f-13'>ITEMS</th>
                                <th className='fw-600 f-13'>TOTAL COUNT</th>
                                <th className='fw-600 f-13'>TOTAL AMOUNT</th>
                                <th className='fw-600 f-13'>PAYMENT STATUS</th>
                                <th className='fw-600 f-13'>DELIVERY STATUS</th>
                                <th className='fw-600 f-13'>ACTION</th>
                            </tr>
                        </thead>
                        <tbody id='table'>
                           
                            <tr>
                                <td className='fw-500 f-xs'>#02A45</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Sam Alex</td>
                                <td className='fw-500 f-xs'>SE1 2UP</td>
                                <td className='fw-500 f-xs'>10/10/2023</td>
                                <td className='fw-500 f-xs'>05/10/2023</td>
                                <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                                <td className='fw-500 f-xs'>4 Kg</td>
                                <td className='fw-500 f-xs'>£ 460.02</td>
                                <td className='fw-500 f-xs underline' data-toggle="modal" data-target="#exampleModalstaus">Success</td>
                                <td className='fw-500 f-xs'><div className='status red f-13'>Pending</div></td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs'>#02A45</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Sam Alex</td>                                <td className='fw-500 f-xs'>SE1 2UP</td>
                                <td className='fw-500 f-xs'>10/10/2023</td>
                                <td className='fw-500 f-xs'>05/10/2023</td>
                                <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                                <td className='fw-500 f-xs'>4 Kg</td>
                                <td className='fw-500 f-xs'>£ 460.02</td>
                                <td className='fw-500 f-xs underline'>Cancelled</td>
                                <td className='fw-500 f-xs'><div className='status grey f-13'>Transit</div></td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs'>#02A45</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Sam Alex</td>                                <td className='fw-500 f-xs'>SE1 2UP</td>
                                <td className='fw-500 f-xs'>10/10/2023</td>
                                <td className='fw-500 f-xs'>05/10/2023</td>
                                <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                                <td className='fw-500 f-xs'>4 Kg</td>
                                <td className='fw-500 f-xs'>£ 460.02</td>
                                <td className='fw-500 f-xs underline'>Success</td>
                                <td className='fw-500 f-xs'><div className='status green f-13'>Delivered</div></td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs'>#02A45</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Sam Alex</td>                                <td className='fw-500 f-xs'>SE1 2UP</td>
                                <td className='fw-500 f-xs'>10/10/2023</td>
                                <td className='fw-500 f-xs'>05/10/2023</td>
                                <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                                <td className='fw-500 f-xs'>4 Kg</td>
                                <td className='fw-500 f-xs'>£ 460.02</td>
                                <td className='fw-500 f-xs underline'>Success</td>
                                <td className='fw-500 f-xs'><div className='status orange f-13'>Order Placed</div></td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className='fw-500 f-xs'>#02A45</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Manchester</td>
                                <td className='fw-500 f-xs'>Sam Alex</td>                                <td className='fw-500 f-xs'>SE1 2UP</td>
                                <td className='fw-500 f-xs'>10/10/2023</td>
                                <td className='fw-500 f-xs'>05/10/2023</td>
                                <td className='fw-500 f-xs'><button type='button' data-toggle="modal" data-target="#exampleModal" className='underline fw-600 brick-clr'>2 Items</button></td>
                                <td className='fw-500 f-xs'>4 Kg</td>
                                <td className='fw-500 f-xs'>£ 460.02</td>
                                <td className='fw-500 f-xs underline'>Pending</td>
                                <td className='fw-500 f-xs'><div className='status yellow f-13'>Processing</div></td>
                                <td className='fw-500'>
                                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                                    <button><i class="fa-solid fa-download clr-33363F"></i></button>
                                </td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                {/* Popup Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body">
        <div className='d-flex justify-content-between w-100 mb-3 bb-dashed pb-2'>
           <div className='fw-600'>Items</div>
           <div className='fw-600 text-center'>Available</div>
           <div className='fw-600 text-end'>Purchased</div>

        </div>
        <div className='d-flex justify-content-between w-100 mb-2'>
           <div className='w-50 fw-500 f-xs'>Seabass (Cleaned)</div>
           <div className='w-50 fw-600 text-center brick-clr'>1kg</div>
           <div className='w-50 fw-600 text-end brick-clr'>1kg</div>
        </div>
        <div className='d-flex justify-content-between w-100 mb-2'>
           <div className='w-50 fw-500 f-xs'>Beef (Cleaned)</div>
           <div className='w-50 fw-600 brick-clr text-center'>1kg</div>
           <div className='w-50 fw-600 brick-clr text-end'>1kg</div>
        </div>
        <div className='d-flex justify-content-between w-100 mb-2'>
           <div className='w-50 fw-500 f-xs'>Mutton (Cleaned)</div>
           <div className='w-50 fw-600 text-center brick-clr'>2kg</div>
           <div className='w-50 fw-600 text-end brick-clr'>2kg</div>
        </div>
    
       
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="exampleModalstaus" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
     
      <div className="modal-body p-3">
        <div className='fw-600'>PAYMENT STATUS</div>
        <div className='status green my-3 w-50 f-xs fw-500 py-2'>Product Delivered</div>
        <div className='f-xs fw-500'>
            The cancellation of this product is due to an issue with the delivery date. The
            customer required the product at the earliest possible date, but unfortunately,
            we are unable to provide delivery to their location within the desired timeframe
            As a result, they have decided to cancel the products.
        </div>
       
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default Sales
