import React, { Component } from 'react'
import './Userhome.css'
import Carousel from 'react-bootstrap/Carousel';
import coursal1 from '../../images/Userhome/Carousal1.png'
import coursal2 from '../../images/Userhome/Carousal2.png'
import coursal3 from '../../images/Userhome/Carousal3.jpg'
import whychooseus from '../../images/Userhome/whychooseus.png'
import userimg from '../../images/userimg2.png'
import { Link } from 'react-router-dom'
// import latestdelivery from '../../images/Userhome/latestdelivery.svg'
import img3 from '../../images/Topselling/topsellingfish2.jpg';
import Slider from 'react-slick'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export class Userhome extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };


    const settings2 = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000
    };
    return (
      <div className='userhome'>
       
      
        <div className='mb-5 home-carousal'>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ coursal1 }
                alt="First slide"
              />
              <Carousel.Caption>
                <div className='f-70 brick-clr ff-carousaly fw-500 lh-72 mb-1 mb-lg-2'>Fresh from the Sea</div>
                <div className='black-clr f-sm fw-500 desc'>Dive into our selection of the freshest, sustainably-sourced
                    fish that will take your meals to the next level.
                </div>
                <button className='lightbrick-btn mt-2 mt-lg-5 px-2 px-lg-3 f-14 shopnowbtn'>Shop Now<i className="fa-solid fa-chevron-right ms-2 f-xs"></i></button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ coursal2 }
                alt="Second slide"
              />

<Carousel.Caption>
                <div className='f-70 brick-clr ff-carousaly fw-500 lh-72 mb-1 mb-lg-2'>Fresh from the Sea</div>
                <div className='black-clr f-sm fw-500 desc'>Dive into our selection of the freshest, sustainably-sourced
                    fish that will take your meals to the next level.
                </div>
                <button className='lightbrick-btn mt-2 mt-lg-5 px-2 px-lg-3 f-14 shopnowbtn'>Shop Now<i className="fa-solid fa-chevron-right ms-2 f-xs"></i></button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ coursal3 }
                alt="Third slide"
              />

<Carousel.Caption>
                <div className='f-70 brick-clr ff-carousaly fw-500 lh-72 mb-1 mb-lg-2'>Fresh from the Sea</div>
                <div className='black-clr f-sm fw-500 desc'>Dive into our selection of the freshest, sustainably-sourced
                    fish that will take your meals to the next level.
                </div>
                <button className='lightbrick-btn mt-2 mt-lg-5 px-2 px-lg-3 f-14 shopnowbtn'>Shop Now<i className="fa-solid fa-chevron-right ms-2 f-xs"></i></button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className='d-flex flex-column flex-lg-row w-100 bestfeatures'>

          <div className='d-flex w-50-resp-mob mb-3 mb-lg-0'>
            <div className='box-shadow w-100 px-4 py-3 radius-7 d-flex bg-white align-items-center me-3 flex-column text-center flex-lg-row text-lg-start'>
              <div className='mb-2 mb-lg-0'><i className="fa-solid fa-truck fa-3x lightbrick"></i></div>
              <div className='ms-2 black-clr fw-600 f-xs'>
                <div>Fast Delivery</div>
                <div>Start from <i className="fa-solid fa-sterling-sign"></i>10</div>
              </div>
            </div>
            <div className='box-shadow w-100 px-4 py-3 radius-7 d-flex bg-white align-items-center me-0 me-lg-3 flex-column flex-lg-row text-center text-lg-start'>
              <div className='mb-2 mb-lg-0'><i className="fa-solid fa-sack-dollar fa-3x lightbrick"></i></div>
              <div className='ms-2 black-clr fw-600 f-xs'>
                <div>Money Guarantee</div>
                <div>7 Days Back</div>
              </div>
            </div>
          </div>

          <div className='d-flex w-50-resp-mob'>
            <div className='box-shadow w-100 px-4 py-3 radius-7 d-flex bg-white align-items-center me-3 flex-column flex-lg-row text-center text-lg-start'>
              <div className='mb-2 mb-lg-0'><i className="fa-solid fa-clock fa-3x lightbrick"></i></div>
              <div className='ms-2 black-clr fw-600 f-xs'>
                <div>2 Days</div>
                <div>For Free Return</div>
              </div>
            </div>
            <div className='box-shadow w-100 px-4 py-3 radius-7 bg-white d-flex align-items-center flex-column flex-lg-row text-center text-lg-start'>
              <div className='mb-2 mb-lg-0'><i className="fa-solid fa-money-check fa-3x lightbrick"></i></div>
              <div className='ms-2 black-clr fw-600 f-xs'>
                <div>Payment</div>
                <div>Secure System</div>
              </div>
            </div>
          </div>
     
        </div>

     
        <div className='ourbestdeal mt-5'>
          <div className='f-xl text-center black-clr fw-600 mb-3'>Our Best Deals</div>
           <div className='my-4 text-center d-flex flex-column flex-lg-row justify-content-center'>
            <button className='round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0'>All</button>
            <button className='round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0'>Beef Liver</button>
            <button className='round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0'>Prawn</button>
            <button className='round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0'>Indian Mackerel</button>
            <button className='round-lightbrick-btn-outline f-xs px-4 me-2 mb-3 mb-lg-0'>Golden pomfret</button>
          </div> 
{/* 
          <Slider ref={slider => (this.slider = slider)} {...ourbestdealsettings}>
<div className='px-2 px-lg-1'><button className='round-lightbrick-btn-outline w-100 f-xs px-4 me-2'>All</button></div>
<div className='px-2 px-lg-1'><button className='round-lightbrick-btn-outline w-100 f-xs px-4 me-2'>Beef Liver</button></div>
<div className='px-2 px-lg-1'><button className='round-lightbrick-btn-outline w-100 f-xs px-4 me-2'>Prawn</button></div>
<div className='px-2 px-lg-1'><button className='round-lightbrick-btn-outline w-100 f-xs px-4 me-2'>Indian Mackerel</button></div>
<div className='px-2 px-lg-1'><button className='round-lightbrick-btn-outline w-100 f-xs px-4 me-2'>Golden pomfret</button></div>
            </Slider> */}

        



        </div>

          <div className='bestdealcarousal'>
            <Slider ref={slider => (this.slider = slider)} {...settings}>

              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='item p-3'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='mt-2 f-xs'><span className='fw-600 brick-clr'>Lamb Leg</span><span className='fw-500'>(chopped)</span></div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2'>
                    <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                    <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
                </div>
              </div>
              
            </Slider>
            {/* <div style={{ textAlign: "center" }} className='p-3'>
              <button className="button" onClick={this.play}>
                <i class="fa-regular fa-circle-play brick-clr"></i>
              </button>
              <button className="button" onClick={this.pause}>
                <i class="fa-regular fa-circle-pause brick-clr"></i>
              </button>
            </div> */}
          </div>

        {/* --------- why choose us start ----------- */}

        <div className='bg-F7EBEC py-5 d-none d-lg-block radius-11 d-flex relative pl-110 whychooseus'>
          <div>
            <div className='black-clr f-xl mb-3'><b>Why You Choose Us</b></div>
            <div className='clr-898989 f-xs mb-4'>
              Savor the taste of quality and freshness in <br></br>
              every bite at our meat and fish shop. <br></br>
              Choose us for unparalleled taste and selection
            </div>
            <Link to='/Layout5/Aboutus' className='round-lightbrick-btn-outline solid px-3 py-2 f-14 underline-none'>Learn More</Link>

          </div>
          <div className='absolute whychooseuspositionimg'>
            <img src={whychooseus} alt='' className='whychooseusimg'></img>
          </div>
        </div>

        <div className='bg-F7EBEC d-block d-lg-none text-center pb-5 pt-3 radius-11 d-flex flex-column relative align-items-center pl-110 whychooseus'>
          <div className=''>
            <img src={whychooseus} alt='' className='whychooseusimg'></img>
          </div>
          <div>
            <div className='black-clr f-xl mb-3'><b>Why You Choose Us</b></div>
            <div className='clr-898989 f-xs mb-4 px-3'>
              Savor the taste of quality and freshness in <br></br>
              every bite at our meat and fish shop. <br></br>
              Choose us for unparalleled taste and selection
            </div>
            <Link to='/Layout5/Aboutus' className='round-lightbrick-btn-outline solid px-3 py-2 f-14 underline-none'>Learn More</Link>

          </div>
        </div>

        {/* --------- why choose us end ------------ */}


        <div className='text-center weeklyspecial mt-5'>
          <div className='black-clr f-lg fw-600 mb-3'>Weekly Special</div>
            <div className='w-100 d-flex flex-column flex-lg-row justify-content-center'>
              <div className='bg-white box-shadow px-3 py-4 radius-7 text-center d-flex flex-column justify-content-center mx-4 mx-lg-5 onhovergradbrick mb-4 mb-lg-0'>
                  <div className='mb-3 relative'>
                  <img src={img3} alt='' className='topsellingfish-items'></img>
                  <div className='absolute heartposition'>
                    <i className="fa-regular fa-heart f-md"></i>
                  </div>
                  </div>
                  <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                  <div className='f-xxs clr-565B67'>A quality British fish</div>
                  <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                  <div className='d-flex mt-2 px-3 justify-content-center w-100'>
                        <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                        <div className='f-xxs kilobg'>2 Kg</div>
                    </div>
              </div>
              <div className='bg-white box-shadow px-3 py-4 radius-7 text-center onhovergradbrick mx-4 mx-lg-0 mb-4 mb-lg-0'>
                <div className='mb-3 relative'>
                <img src={img3} alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'>
                  <i className="fa-regular fa-heart f-md"></i>
                </div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67'>A quality British fish</div>
                <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                <div className='d-flex mt-2 px-3 justify-content-center'>
                      <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                      <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
              </div>
              <div className='bg-white box-shadow px-3 py-4 radius-7 text-center mx-4 mx-lg-5 onhovergradbrick'>
                <div className='mb-3 relative'>
                <img src={img3} alt='' className='topsellingfish-items'></img>
                <div className='absolute heartposition'>
                  <i className="fa-regular fa-heart f-md"></i>
                </div>
                </div>
                <div className='brick-clr f-xs fw-600'>Big Tuna Fish</div>
                <div className='f-xxs clr-565B67'>A quality British fish</div>
                <div className='mt-1 f-xs fw-600 brick-clr'>£10(£3.30/kg)</div>
                <div className='d-flex mt-2 px-3 justify-content-center'>
                      <button className='lightbrick-btn f-13 h-27'>Add to bag</button>
                      <div className='f-xxs kilobg'>2 Kg</div>
                  </div>
              </div>
            </div>
        </div>

        <div className='userreview text-center m-1 m-lg-5 mt-5'>
        <div className='black-clr f-lg fw-600 mb-4'>What Our Clients Says</div>

        <Slider className='' ref={slider => (this.slider = slider)} {...settings2}>
              <div className='bg-extralightbrick radius-11 p-3 p-lg-5'>
                <div className='bg-white d-flex radius-35 justify-content-center flex-column align-items-center py-3 px-0 px-lg-3'>
                <div><img src={userimg} alt='' className=''></img></div>
                <div className='fw-600 mt-2'>Liam Benjamin</div>
                <div className='d-flex mt-1'>
                <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-regular fa-star lightbrick"></i></button>
                </div>
                <div className='w-75 text-center f-xs bg-white py-3 px-0 px-lg-3 radius-11'>
                  I recently visited Sibu Fish and Meat shop and was very impressed with the selection
                  and quality of products on offer. The fish and seafood were incredibly fresh and the
                  meats were of high quality. The staff were also very friendly and helpful in helping me
                  choose the right cuts for my needs. Overall, I had a great experience and will definitely
                  be returning as a regular customer.
                </div>
                </div>
              </div>
              <div className='bg-extralightbrick radius-11 p-3 p-lg-5'>
                <div className='bg-white d-flex radius-35 justify-content-center flex-column align-items-center py-3 px-0 px-lg-3'>
                <div><img src={userimg} alt='' className=''></img></div>
                <div className='fw-600 mt-2'>Liam Benjamin</div>
                <div className='d-flex mt-1'>
                <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-solid fa-star lightbrick"></i></button>
                  <button><i class="fa-regular fa-star lightbrick"></i></button>
                </div>
                <div className='w-75 text-center f-xs bg-white py-3 px-0 px-lg-3 radius-11'>
                  I recently visited Sibu Fish and Meat shop and was very impressed with the selection
                  and quality of products on offer. The fish and seafood were incredibly fresh and the
                  meats were of high quality. The staff were also very friendly and helpful in helping me
                  choose the right cuts for my needs. Overall, I had a great experience and will definitely
                  be returning as a regular customer.
                </div>
                </div>
              </div>
              
        </Slider>
   
        </div>


        <div className='text-center latestdelivery mt-5'>
          <div className='black-clr f-lg fw-600 mb-4'>Our Latest Deliveries</div>
          <div className='d-flex flex-column flex-lg-row w-100'>
            <div className='bg-latestdelivery p-4 d-flex flex-column align-items-center justify-content-center bg-white mb-4 mb-lg-0'>
                <div className='bg-lightbrick location'>
                  <span className="material-symbols-outlined white-clr f-70">
                  distance
                  </span>
                </div>
                <div className='fw-500 f-14 black-clr mt-5'>
                  15 High Street, Dewsbury United Kingdom, WF12 0PY
                </div>
                <button className='radius-7 black-btn f-sm px-3 mt-2'>View</button>
            </div>
            <div className='bg-latestdelivery p-4 d-flex flex-column align-items-center justify-content-center bg-white mx-0 mx-lg-3 mb-4 mb-lg-0'>
                <div className='bg-lightbrick location'>
                  <span className="material-symbols-outlined white-clr f-70">
                  distance
                  </span>
                </div>
                <div className='fw-500 f-14 black-clr mt-5'>
                  15 High Street, Dewsbury United Kingdom, WF12 0PY
                </div>
                <button className='radius-7 black-btn f-sm px-3 mt-2'>View</button>
            </div>
            <div className='bg-latestdelivery p-4 d-flex flex-column align-items-center justify-content-center bg-white'>
                <div className='bg-lightbrick location'>
                  <span className="material-symbols-outlined white-clr f-70">
                  distance
                  </span>
                </div>
                <div className='fw-500 f-14 black-clr mt-5'>
                  15 High Street, Dewsbury United Kingdom, WF12 0PY
                </div>
                <button className='radius-7 black-btn f-sm px-3 mt-2'>View</button>
            </div>
          </div>
        </div>

        <div className='bg-F7EBEC my-5 px-3 px-lg-0 radius-11 bg-keepintouch w-100'>
          <div className='fw-600 black-clr f-lg'>Keep in Touch With Us !</div>
          <div className='clr-868790 f-xs fw-500 text-center'>Please press on below button and enter your email address.</div>
          <div className='mt-3 w-50-resp-mob text-center relative'>
            <input className='w-100 radius-7' placeholder='Enter your email address'></input>
            <button className='absolute lightbrick-btn f-xs'>Sign In</button>
          </div>
        </div>
        

      </div>

    );
  }

}

export default Userhome