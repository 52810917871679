import React, { Component } from 'react'
import { Outlet } from "react-router-dom"
// import Navbar from '../Navbar/Navbar'
import './Layout3.css';
import Toolbar from '../Toolbar/Toolbar';
// import BrickLogo from '../../images/brick-logo.svg'


export class Layout3 extends Component {
  render() {
    return (
        <React.Fragment>
        <div className='w-100 d-flex ff-mon'>
         

         <div className='content_wrpr w-100 d-flex flex-column'>
           <Toolbar></Toolbar>
           <div className='w-100 d-flex flex-column p-4'>
             <Outlet />
           </div>
         </div>
       </div>
       </React.Fragment>
    )
  }
}

export default Layout3
