import React, { Component } from 'react'
import './Customer.css'
// import img1 from '../../images/Customer/img1.svg'
import { Link } from 'react-router-dom'
import $ from 'jquery';


export class Customer extends Component {
  componentDidMount() {
    $("#search").on("keyup", function() {
      var value = $(this).val().toLowerCase();
      $("#table tr").filter(function() {
        $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        return true
      });
    });
  }
  render() {
    return (
      <div className='px-0 px-md-4 py-3 customer'>
        <div className='text-end'>
            {/* <button className='lightbrick-btn f-xs px-4'>Add Product</button> */}
            <Link to='/Layout2/Addcustomer' className='lightbrick-btn f-xs px-4 add-prod-link'>New Customer</Link>
        </div>

        <div className='d-flex align-items-center my-3'>
            <div className='relative w-100'>
                <input className='nav-search-inp w-100 fw-600' placeholder='Start typing search for customer' id='search'></input>
                <div className='search-i-position'><i className="fa-solid fa-magnifying-glass"></i></div>
            </div>
        </div>

        <div className='w-100 product-table'>
          <table class="rwd-table mb-2">
            <tbody id='table'>
              <tr>
                  <th>Customer</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Postal Code</th>
                  <th>Total Spent</th>
                  <th className='text-center'>More</th>
              </tr>
              <tr>
                  <td data-th="Customer">
                  Alex Taylor 
                  </td>
                  <td data-th="Phone">
                  077 3922 5112
                  </td>
                  <td data-th="Email">
                  alextaylor@gmail.com
                  </td>
                  <td data-th="Postal Code">
                  SW1A 1AA
                  </td>
                  <td data-th="Total Spent">
                  £ 45,123.18
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Customer">
                  Alex Taylor 
                  </td>
                  <td data-th="Phone">
                  077 3922 5112
                  </td>
                  <td data-th="Email">
                  aparnapurishothaman @gmail.com
                  </td>
                  <td data-th="Postal Code">
                  SW1A 1AA
                  </td>
                  <td data-th="Total Spent">
                  £ 45,123.18
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Customer">
                  Alex Taylor 
                  </td>
                  <td data-th="Phone">
                  077 3922 5112
                  </td>
                  <td data-th="Email">
                  alextaylor@gmail.com
                  </td>
                  <td data-th="Postal Code">
                  SW1A 1AA
                  </td>
                  <td data-th="Total Spent">
                  £ 45,123.18
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Customer">
                  Alex Taylor 
                  </td>
                  <td data-th="Phone">
                  077 3922 5112
                  </td>
                  <td data-th="Email">
                  alextaylor@gmail.com
                  </td>
                  <td data-th="Postal Code">
                  SW1A 1AA
                  </td>
                  <td data-th="Total Spent">
                  £ 45,123.18
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
              <tr>
                  <td data-th="Customer">
                  Alex Taylor 
                  </td>
                  <td data-th="Phone">
                  077 3922 5112
                  </td>
                  <td data-th="Email">
                  alextaylor@gmail.com
                  </td>
                  <td data-th="Postal Code">
                  SW1A 1AA
                  </td>
                  <td data-th="Total Spent">
                  £ 45,123.18
                  </td>
                  <td data-th="More">
                    <button><i class="fa-solid fa-eye clr-33363F"></i></button>
                    <button><i class="fa-solid fa-pen clr-33363F"></i></button>
                    <button><i class="fa-solid fa-trash clr-33363F"></i></button>
                  </td>

              </tr>
            </tbody>
          </table> 
        </div>
      </div>
    )
  }
}

export default Customer